import React from 'react';
import {Typography, Box} from '@mui/material';
import './Registeration.scss';

const RegistrationConfirmation = () => {
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        maxWidth: '800px',
        maxHeight: '450px',
        margin: '8% auto 0 auto',
      }}
    >
      <Box className="thank-you-wrapper">
        <img src={process.env.PUBLIC_URL + '../../img/thank-you-icon.svg'} alt="" />
        <Typography variant="h2">Thank you for registering! </Typography>
        <Typography variant="p" className="subtitle">
          We’ve received your details and will contact you within 48 hours.
        </Typography>
        {/* <Button type="submit" size="large" variant="contained" color="primary" >
         Create Instructor Account
      </Button> */}
      </Box>
    </Box>
  );
};

export default RegistrationConfirmation;
