import {createCipheriv, createDecipheriv} from 'crypto-browserify';
import config from '../config/config';

const iv = config.cryptoKey.substr(0, 16);
// Encrypt provided data with crypto key
export const encryptData = details => {
  const key = createCipheriv('aes-256-cbc', config.cryptoKey, iv);
  let base64 = key.update(details, 'binary', 'base64');
  base64 += key.final('base64');
  return base64;
};

// Decript provided data with crypto key.
export const decryptData = data => {
  const key = createDecipheriv('aes-256-cbc', config.cryptoKey, iv);
  let pass = key.update(data, 'base64', 'binary');
  pass += key.final('binary');
  return pass;
};
