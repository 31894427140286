import * as dashboardType from '../../constants/ActionTypes';
import {all, fork, put, takeLatest, select} from 'redux-saga/effects';
import axios from 'util/Api';
import {
  saveDashboardData,
  dashboardLoader,
  saveDashboardCourseSData,
  setRemainingSeats,
  setPasswordResetRequests,
  fetchPasswordResetRequests,
} from '../../redux/actions/Dashboard';
import {schoologyAppCoursesList} from '../../redux/actions/Auth';
import {errorHandler} from '../actions/Error';
import {push} from 'connected-react-router';

export function* fetchNewPasswordRequestSaga({payload}) {
  try {
    yield put(dashboardLoader(true));
    const response = yield axios.post('/reset-student-password', payload);
    if (response.status === 200) {
      yield put(fetchPasswordResetRequests());
    }
    yield put(dashboardLoader(false));
  } catch (error) {
    yield put(dashboardLoader(false));
    yield put(errorHandler({error: error, page: ''}));
  }
}

export function* fetchNewPasswordRequestWatcher() {
  yield takeLatest(dashboardType.FETCH_NEW_PASSWORD_REQUEST, fetchNewPasswordRequestSaga);
}

export function* fetchPasswordResetRequestsSaga() {
  try {
    yield put(dashboardLoader(true));
    const response = yield axios.get('/instructor/dashboard/password-reset-requests');
    if (response.status === 200) {
      yield put(setPasswordResetRequests(response.data.data));
    }
    yield put(dashboardLoader(false));
  } catch (error) {
    yield put(dashboardLoader(false));
    yield put(errorHandler({error: error, page: ''}));
  }
}

export function* fetchPasswordResetRequestsWatcher() {
  yield takeLatest(dashboardType.FETCH_PASSWORD_RESET_REQUESTS, fetchPasswordResetRequestsSaga);
}

export function* fetchRemainingSeats() {
  try {
    yield put(dashboardLoader(true));
    const response = yield axios.get('/get-district');
    if (response.status === 200) {
      yield put(setRemainingSeats(response.data[0]));
    }
    yield put(dashboardLoader(false));
  } catch (error) {
    yield put(dashboardLoader(false));
    yield put(errorHandler({error: error, page: ''}));
  }
}

// Watcher for remaining seats
export function* fetchRemainingSeatsWatcher() {
  yield takeLatest(dashboardType.FETCH_REMAINING_SEATS, fetchRemainingSeats);
}

// Dashboard details fetch worker
export function* fetchDashboardSectionData(payload) {
  const reduxState = yield select();
  const {auth} = reduxState;
  const {ssoLoginDetails} = auth;
  try {
    yield put(dashboardLoader(true));
    const conditionalUrl = !!ssoLoginDetails?.section_id
      ? '/clever/instructor/sections?section_id=' + ssoLoginDetails?.section_id
      : '/clever/instructor/sections';
    const ssoSectionId = yield axios.get(conditionalUrl);
    // const ssoSectionId = yield axios.get(`/clever/instructor/sections`);
    if (ssoSectionId.status === 200) {
      yield put(saveDashboardCourseSData({dashboardCoursesData: ssoSectionId.data}));
      yield put(dashboardLoader(false));
    }
  } catch (error) {
    yield put(dashboardLoader(false));
    // yield put(errorHandler(error));
    yield put(errorHandler({error: error, page: ''}));
  }
}

// Dashboard details fetch WATCHER
export function* fetchDashboardDataWatcher() {
  yield takeLatest(dashboardType.FETCH_DASHBOARD_DATA, fetchDashboardSectionData);
}

// DashboardCourses details fetch worker
export function* fetchUserCoursesData({payload}) {
  try {
    yield put(dashboardLoader(true));
    //const section_id = ssoSectionId.data[0].section_id
    const dashboardDetails = yield axios.get(`/clever/instructor/sections/${payload}/users`);
    if (dashboardDetails.status === 200) {
      yield put(saveDashboardData({dashboardTableData: dashboardDetails.data}));
      yield put(push('/app/instructor/course'));

      yield put(dashboardLoader(false));
    }
    yield put(dashboardLoader(false));
  } catch (error) {
    yield put(dashboardLoader(false));
    // yield put(errorHandler(error));
    yield put(errorHandler({error: error, page: ''}));
  }
}

// DashboardCourses details fetch WATCHER
export function* fetchDashboardCoursesDataWatcher() {
  yield takeLatest(dashboardType.FETCH_DASHBOARD_COURSES_DATA, fetchUserCoursesData);
}

// fetch SchoologyApp Add course details
function* schoologyAddCourseData({payload: {context_id, client_id, deployment_id, section_id}}) {
  try {
    yield put(dashboardLoader(true));
    const payoadForAPI = {context_id: context_id, client_id: client_id, deployment_id: deployment_id, section_id: section_id};
    const schoologyData = yield axios.post(`/schoology-add-course`, payoadForAPI);

    if (schoologyData.status === 200) {
      yield put(push('/app/instructor/addcoursesuccess'));
      // ToastManager.success(schoologyData.data.message, null, 5000);
      yield put(schoologyAppCoursesList(true));
      yield put(dashboardLoader(false));
    }
    yield put(dashboardLoader(false));
  } catch (error) {
    yield put(dashboardLoader(false));
    yield put(errorHandler({error: error, page: ''}));
  }
}

function* downloadCertificateForStudent({payload: {student_id, section_id}}) {
  console.log('hello');
  try {
    yield put(dashboardLoader(true));
    const downloadCertificateResponse = yield axios.post(`/request-certificate-student`, {section_id});
    console.log('downloadCertificateResponse', downloadCertificateResponse);
    if (downloadCertificateResponse.status === 200) {
      const {
        data: {certificate_temp_link},
      } = downloadCertificateResponse;

      window.open(certificate_temp_link, '_blank');

      yield put(dashboardLoader(false));
    }
    yield put(dashboardLoader(false));
  } catch (error) {
    yield put(dashboardLoader(false));
    yield put(errorHandler({error: error, page: ''}));
  }
}

export function* schoologyAddCourseDataWatcher() {
  yield takeLatest(dashboardType.FETCH_SCHOOLOGY_ADD_COURSE_DETAILS, schoologyAddCourseData);
}

// ------ ROOT SAGA -----------------
export function* downloadCertificateForStudentWatcher() {
  yield takeLatest(dashboardType.DOWNLOAD_CERTIFICATE_FOR_STUDENT, downloadCertificateForStudent);
}

export default function* rootSaga() {
  yield all([
    fork(fetchDashboardDataWatcher),
    fork(fetchDashboardCoursesDataWatcher),
    fork(schoologyAddCourseDataWatcher),
    fork(fetchRemainingSeatsWatcher),
    fork(fetchPasswordResetRequestsWatcher),
    fork(fetchNewPasswordRequestWatcher),
    fork(downloadCertificateForStudentWatcher),
  ]);
}
