import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';

function AddCourseModal({ open, onClose, onSubmit }) {
  const [code, setCode] = useState('');
  const [isValid, setIsValid] = useState(false);

  const handleCodeChange = e => {
    const input = e.target.value;
    const isValidCode = /^[A-Za-z0-9]{6}$/.test(input); 
    setCode(input);
    setIsValid(isValidCode);
  };

  const handleFormSubmit = event => {
    event.preventDefault();
    if (isValid) {
     onSubmit(code);
      setCode('');
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
          width: '659px',
          position: 'relative',
        },
      }}
    >
      <DialogTitle id="form-dialog-title">
        Add Course
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            right: '16px',
            top: '16px',
            color: '#555',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <DialogContentText style={{fontWeight: '600', color: '#000'}}>
          Enter Your Course Code
        </DialogContentText>
        <TextField
          autoFocus
          id="code"
          label="Code"
          type="text"
          fullWidth
          variant="outlined"
          value={code}
          onChange={handleCodeChange}
          inputProps={{ maxLength: 6 }}
          helperText={code.length > 0 && !isValid ? 'Code must be exactly 6 alphanumeric characters' : ''}
          error={code.length > 0 && !isValid}
        />
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleFormSubmit}
          color="primary"
          variant="contained"
          fullWidth
          style={{ color: 'white', margin: '2.5rem 1rem 0rem 1rem' }}
          disabled={!isValid}
        >
          Add Code
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddCourseModal;
