import React from 'react';
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import asyncComponent from '../../../util/asyncComponent';
import {getDefaultDomainDetails} from '../../../util/Extra';
import {fetchAmerikoolerProfileData} from 'redux/actions/Profile';
import {isEmpty} from 'lodash';
import {bindActionCreators} from 'redux';
import {QUVA} from 'constants/Constants';
class Learner extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  componentDidMount() {
    const profileData = this.props.amerikoolerProfileData;
    if (isEmpty(profileData)) {
      this.props.fetchAmerikoolerProfileData();
    }
  }

  render() {
    const {match, location, userRole, amerikoolerProfileData} = this.props;
    if (location.pathname === `/app/${userRole}`) {
      if (getDefaultDomainDetails().themeColor === 'tdn-theme' || getDefaultDomainDetails().instituteName === QUVA) {
        return <Redirect to={`/app/${userRole}/dashboard`} />;
      } else {
        return <Redirect to={`/app/${userRole}/course`} />;
      }
    }
    const isForcedPwdChangeRoute = !Number(amerikoolerProfileData?.hasPasswordChanged);
    const componentPwdChange = asyncComponent(() =>
      isForcedPwdChangeRoute ? import('./routes/ForcedChangePassword/ForcedChangePassword') : import('./routes/ChangePassword/ChangePassword'),
    );
    return (
      <React.Fragment>
        <Switch>
          <Route path={`${match.url}/profile`} component={asyncComponent(() => import('./routes/AmerikoolerUserProfile/AmerikoolerUserProfile'))} />
          <Route path={`${match.url}/change-password`} component={componentPwdChange} />
          <Route path={`${match.url}/course`} component={asyncComponent(() => import('./routes/Course/Course'))} />
          <Route path={`${match.url}/dashboard`} component={asyncComponent(() => import('./routes/StudentDashboard/StudentDashboard'))} />
          <Route component={asyncComponent(() => import('components/Error404/Error404'))} />
        </Switch>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchAmerikoolerProfileData,
    },
    dispatch,
  );
};

const mapStateToProps = ({profile, auth}) => {
  const {amerikoolerProfileData} = profile;
  const {loader, authUser, signinStatus, userRole, clientConfigration} = auth;
  return {
    loader,
    authUser,
    signinStatus,
    userRole,
    clientConfigration,
    amerikoolerProfileData,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Learner));
