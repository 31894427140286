import React, {useEffect} from 'react';
import {connect,useDispatch, useSelector} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {fetchAmerikoolerProfileData} from 'redux/actions';
import {encryptData} from 'util/Crypto';
import {CircularProgress} from '@material-ui/core';
import {getDefaultDomainDetails} from 'util/Extra';
import './SuperAdmin.scss';
import Error404 from 'components/Error404/Error404';

const SuperAdmin = () => {
  const { amerikoolerProfileData, clientConfigration, userId } = useSelector(({ auth, profile }) => ({
    clientConfigration: auth.clientConfigration,
    userId: auth.userId,
    amerikoolerProfileData: profile.amerikoolerProfileData,
  }));
  const dispatch = useDispatch();
  const redirectUrl = clientConfigration?.serverConfig?.institute_login_validate_url;

  useEffect(() => {
    dispatch(fetchAmerikoolerProfileData());
  }, [dispatch]);
  useEffect(() => {
    if(!redirectUrl || !amerikoolerProfileData) return;
    const {email, first_name, last_name, city, phone, zip, state} = amerikoolerProfileData;
    try {
    const encryptedParams = encryptData(
      `usr_email=${email}&usr_fname=${first_name}&usr_lname=${last_name}&usr_city=${city}&usr_state=${state}&usr_phone=${phone}&usr_zip=${zip}&usr_id=${userId}&institute_key=${
        getDefaultDomainDetails().institute_key
      }&apikey=${getDefaultDomainDetails().api_key}`,
    );
    window.sessionStorage.clear();
    //redirect to super admin url
    window.location.replace(`${redirectUrl}?${encryptedParams}`);

  } catch (error) {
    console.error("Error during redirection");
    window.sessionStorage.clear();
    window.location.replace('/signin');
  }
  }, [amerikoolerProfileData, redirectUrl, userId]);

  if (!redirectUrl) {
    return <Error404 />;
  }

  return (
    <div className="wrapper">
      <CircularProgress aria-label="Redirecting to admin dashboard" />
      Redirecting to admin dashboard...
    </div>
  );
};
const mapStateToProps = ({ auth, profile}) => {
  
  const { clientConfigration, userId} = auth;
  const {amerikoolerProfileData} = profile;
  return {
    userId,
    amerikoolerProfileData,
    clientConfigration
  };
};

export default withRouter(connect(mapStateToProps, null)(SuperAdmin));