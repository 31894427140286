// const bool = (value, defaultValue) => (value === undefined ? defaultValue : value);

const staging = {
  serverURL: process.env.REACT_APP_SERVER_URL,
  cryptoKey: process.env.REACT_APP_CRYPTO_KEY,
  sessionTimeoutMinuteValue: process.env.REACT_APP_SESSION_TIMEOUT_MIN_VALUE || 20,
  recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  amerikooler: {
    api_key: process.env.REACT_APP_AMERIKOOLER_API_KEY,
    server_url: process.env.REACT_APP_SERVER_URL,
    socket_url: process.env.REACT_APP_SOCKET_URL,
    scorm_launcher_url_link: process.env.REACT_APP_AMERIKOOLER_SCORM_LAUNCHER_URL_LINK,
    institute_key: 2,
    themeColor: process.env.REACT_APP_THEME_COLOR_FOR_AMERIKOOLER,
    instituteName: process.env.REACT_APP_THEME_INTITUTE_NAME_FOR_AMERIKOOLER,
    instituteLogo: process.env.REACT_APP_THEME_INTITUTE_LOGO_FOR_AMERIKOOLER,
  },
  tdn: {
    api_key: process.env.REACT_APP_TDN_API_KEY,
    server_url: process.env.REACT_APP_SERVER_URL,
    socket_url: process.env.REACT_APP_SOCKET_URL,
    scorm_launcher_url_link: process.env.REACT_APP_SCORM_LAUNCHER_URL_LINK,
    institute_key: 3,
    themeColor: process.env.REACT_APP_THEME_COLOR_FOR_TDN,
    instituteName: process.env.REACT_APP_THEME_INTITUTE_NAME_FOR_TDN,
    instituteLogo: process.env.REACT_APP_THEME_INTITUTE_LOGO_FOR_TDN,
  },
  paktolus: {
    api_key: process.env.REACT_APP_API_KEY_FOR_AMERIKOOLER,
    server_url: process.env.REACT_APP_SERVER_URL,
    socket_url: process.env.REACT_APP_SOCKET_URL,
    scorm_launcher_url_link: process.env.REACT_APP_SCORM_LAUNCHER_URL_LINK,
    institute_key: 1,
    themeColor: process.env.REACT_APP_THEME_COLOR_FOR_PAKTOLUS,
    instituteName: process.env.REACT_APP_THEME_INTITUTE_NAME_FOR_PAKTOLUS,
    instituteLogo: process.env.REACT_APP_THEME_INTITUTE_LOGO_FOR_PAKTOLUS,
  },
  quva: {
    api_key: process.env.REACT_APP_QUVA_API_KEY,
    server_url: process.env.REACT_APP_NEW_SERVER_URL,
    socket_url: process.env.REACT_APP_SOCKET_URL,
    scorm_launcher_url_link: process.env.REACT_APP_QUVA_SCORM_LAUNCHER_URL_LINK,
    institute_key: 1,
    themeColor: process.env.REACT_APP_THEME_COLOR_FOR_QUVA,
    instituteName: process.env.REACT_APP_THEME_INTITUTE_NAME_FOR_QUVA,
    instituteLogo: process.env.REACT_APP_THEME_INTITUTE_LOGO_FOR_QUVA,
  },
};

export default staging;
