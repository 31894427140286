import React, {Component, createRef} from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {getDefaultDomainDetails} from '../util/Extra';
import {connect} from 'react-redux';
import {setThemeColor} from '../redux/actions/Setting';
import {clearResetPasswordResponseData, resetPasswordRequest} from '../redux/actions/Auth';
import {bindActionCreators} from 'redux';
import {withStyles} from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import SubmitLoader from '../components/SubmitLoader/SubmitLoader';

import ReCAPTCHA from 'react-google-recaptcha';
import config from '../config/config';
import {isEmpty} from 'lodash';

const styles = theme => ({
  root: {
    '& .MuiInputLabel-formControl': {
      top: '6% !important',
    },
  },
  paper: {
    marginTop: theme.spacing(15),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
    borderRadius: ' 50px',
  },
  input: {
    background: '#f4f7fc',
    '&:-webkit-autofill': {
      background: '#f4f7fc',
    },
  },
  btnsubmit: {
    width: '100%',
    fontFamily: 'ITC Avant Garde Std Md',
    padding: '10px 25px !important',
    borderRadius: ' 50px',
    fontSize: '16px',
    marginBottom: '15px',
    background: '#525CA3',
    '&:hover ': {
      background: '#525CA3',
    },
  },
  error: {
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E05757',
    },
  },
});

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.myRef = createRef();
    this.state = {
      personalDetails: {
        email: '',
      },
      emailDataError: {
        emailError: '',
      },
      message: '',
      showMessage: false,
    };
  }

  validate = () => {
    const {email} = this.state.personalDetails;
    let emailError = '';

    const username_regex = /^[a-zA-Z0-9._@']+$/;
    const email_regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    switch (true) {
      case !email || email.length === 0:
        emailError = 'Required';
        break;
      case !username_regex.test(email) && !email_regex.test(email):
        emailError = 'Invalid Username or Email';
        break;
      default:
        emailError = '';
    }

    this.setState({
      emailDataError: {emailError},
    });

    return !emailError;
  };

  submitForm = async event => {
    event.preventDefault();
    const {email} = this.state.personalDetails;
    const isValid = this.validate();
    const recatpchaToken = await this.myRef.current.executeAsync();
    this.myRef.current.reset();
    if (isValid) {
      this.props.resetPasswordRequest({
        email: email,
        recaptchaToken: recatpchaToken,
      });
    }
  };

  handleChange = e => {
    const {personalDetails} = {...this.state};
    const {emailDataError} = {...this.state};

    personalDetails[e.target.name] = e.target.value;

    if (emailDataError[e.target.name + 'Error'] !== '') {
      emailDataError[e.target.name + 'Error'] = '';
    }

    this.setState(prevState => ({
      ...prevState,
      personalDetails: {...personalDetails},
      emailDataError: {...emailDataError},
    }));
  };

  handleChangeOnBlur = e => {
    const {personalDetails} = {...this.state};
    if (e.target.name === 'email') {
      personalDetails[e.target.name] = e.target.value.trim();
    }
    this.setState(prevState => ({
      ...prevState,
      personalDetails: {...personalDetails},
    }));
  };

  handleClearData = () => {
    this.props.clearResetPasswordResponseData();
    this.props.history.push(`/signin`);
  };

  componentDidMount() {
    const {signinStatus, history, authUser, userRole} = this.props;
    if (authUser !== null && signinStatus && userRole !== null) {
      history.push(`/app`);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {signinStatus, history, authUser, userRole} = nextProps;
    const currentClientFromUrl = sessionStorage.getItem('currentClientFromUrl');
    nextProps.setThemeColor(getDefaultDomainDetails(currentClientFromUrl).themeColor);
    if (authUser !== null && signinStatus && userRole !== null) {
      history.push(`/app`);
    }

    return null;
  }

  render() {
    const {clientConfigration, classes, loader, errorMessage, errorDisplay, pageName, resetPasswordResponse} = this.props;
    const {serverConfig} = !!clientConfigration && clientConfigration;

    const {email} = this.state.personalDetails;
    const {emailError} = this.state.emailDataError;
    return (
      <div className={`app-container`}>
        <div className="app-main-container">
          <div className="login-page forget-password-page">
            <div className="row full-height">
              <div className="col-lg-6 col-md-5 left-section">
                <div className="login-box-wrap forget-password reset-password">
                  <div className="logo-wrap">
                    <img className="" alt="icon" src={serverConfig.institute_logo} />
                  </div>
                  {errorDisplay && pageName === 'ResetPassword' && (
                    <div className="row form-error-wrap">
                      <span className="default-error">
                        <img src="../img/error-icon.svg" alt="loading..."></img>
                        {errorMessage}
                      </span>
                    </div>
                  )}

                  {!isEmpty(resetPasswordResponse) && resetPasswordResponse?.status === 'success' ? (
                    <div>
                      <h2>Reset Password</h2>
                      <p>{resetPasswordResponse.message}</p>
                      <Button
                        type="submit"
                        variant="contained"
                        className="default-btn"
                        color="primary"
                        margin="normal"
                        fullWidth
                        component={Link}
                        onClick={this.handleClearData}
                      >
                        Back to Login
                      </Button>
                    </div>
                  ) : (
                    <>
                      <h2>Reset Your Password</h2>
                      <h3 className="sub-title">
                        Please enter your username or email address. You will receive an email message with instructions on how to reset your password.
                      </h3>
                      <form className={classes.root} noValidate autoComplete="off" onSubmit={this.submitForm}>
                        <Grid container>
                          <Grid xs={12} item>
                            <TextField
                              id="email"
                              name="email"
                              label="Username or Email"
                              variant="outlined"
                              margin="normal"
                              className={classes.error}
                              fullWidth
                              autoFocus
                              error={emailError ? true : false}
                              disabled={loader}
                              value={email}
                              onChange={this.handleChange}
                              onBlur={this.handleChangeOnBlur}
                              inputProps={{
                                style: {
                                  padding: 13,
                                  backgroundColor: emailError ? '#FEF7F5' : '#FFFFFF',
                                },
                              }}
                            />
                            {emailError && (
                              <p
                                className="Error"
                                style={{
                                  color: '#E05757',
                                  fontSize: '14px',
                                  marginTop: '-21px',
                                }}
                              >
                                <ErrorOutlineIcon
                                  style={{
                                    fontSize: '14px',
                                    marginTop: '-4px',
                                    marginRight: '10px',
                                  }}
                                />
                                {emailError}
                              </p>
                            )}
                          </Grid>
                          <Button type="submit" variant="contained" className="default-btn" color="primary" margin="normal" fullWidth disabled={loader}>
                            Get New Password {loader && <SubmitLoader />}
                          </Button>
                        </Grid>
                      </form>
                    </>
                  )}
                  {isEmpty(resetPasswordResponse) && (
                    <Link href="/signin" variant="body2" className="back-to-login" style={{textDecoration: 'none'}}>
                      <img className="" alt="icon" src={process.env.PUBLIC_URL + '../../img/tdn-right-blue.svg'} /> Back to login
                    </Link>
                  )}
                </div>
              </div>
              <div className="col-lg-6 col-md-7 right-section">
                <div className="login-heading-wrap"></div>
              </div>
              <ReCAPTCHA sitekey={config.recaptchaSiteKey} size="invisible" ref={this.myRef} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      clearResetPasswordResponseData,
      resetPasswordRequest,
      setThemeColor,
    },
    dispatch,
  );
};

const mapStateToProps = ({auth, error}) => {
  const {loader, signinStatus, authUser, config, clientConfigration, resetPasswordResponse} = auth;
  const {errorDisplay, errorMessage, pageName} = error;

  return {
    loader,
    signinStatus,
    authUser,
    config,
    errorMessage,
    errorDisplay,
    pageName,
    clientConfigration,
    resetPasswordResponse,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ResetPassword));
