import React, {useState} from 'react';
import {TextField, Button, Grid, Typography, Box, CircularProgress} from '@mui/material';
import './Registeration.scss';
import Divider from '@mui/material/Divider';
import {courseCode, studentRegistrationForm} from 'service/signup';
import {useHistory} from 'react-router-dom';
import {IconButton, InputAdornment} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import {ToastManager} from 'components/ToastManager/ToastManager';
import { first_name_regx, last_name_regx, set_password_regex,  } from 'util/Extra';

const StudentRegistration = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    username: '',
    password: '',
    confirmPassword: '',
    courseCode: '',
    schoolDistrict: '',
    schoolName: '',
    courseLearning: '',
    instructorName: '',
    instructorId: null,
    schoolId: null,
    districtId: null,
  });

  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); 
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); 
  const history = useHistory();

  const validateForm = () => {
    let newErrors = {};

    if (!formData.firstName.trim()) {
      newErrors.firstName = 'First Name is required';
    } else if (!first_name_regx.test(formData.firstName)) {
      newErrors.firstName = 'First Name should be between 3-100 letters only. Numbers and special characters are not accepted.';
    }
    
    if (!formData.lastName.trim()) {
      newErrors.lastName = 'Last Name is required';
    } else if (!last_name_regx.test(formData.lastName)) {
      newErrors.lastName = 'Last Name should be between 3-100 letters only. Numbers and special characters are not accepted.';
    } 

    if (!formData.password) {
      newErrors.password = 'Password is required';
    } else if (!set_password_regex.test(formData.password)) {
      newErrors.password = 'Passwords must contain at least 8 alphanumerical characters and should be include one small letter, one caps letter.';
    } 

    if (formData.password !== formData.confirmPassword) newErrors.confirmPassword = 'Passwords must match';
    if (!formData.courseCode.trim()) {
      newErrors.courseCode = 'Course code is required';
    }
    if (!formData.username.trim()) {
      newErrors.username = 'Username is required';
    } else if (formData.username.length < 5) {
      newErrors.username = 'Username must be at least 5 characters';
    } else if (!/^[a-zA-Z0-9._@']+$/.test(formData.username)) {
      newErrors.username = 'Invalid Username';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleChange = e => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (isFormValid) {
      const studentregisterData = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.username,
        password: formData.password,
        confirm_password: formData.confirmPassword,
        license_key: formData.courseCode,
        district_id: formData.districtId,
        school_id: formData.schoolId,
        instructor_id: formData.instructorId,
      };
      try {
        const result = await studentRegistrationForm(studentregisterData);
        if (result) {
            ToastManager.success('Registration successful!');
          const currentTimeStamp = new Date().getTime();
          history.push(`/ext/login/${result.emailId}/${result.userId}/${result.userTypeId}/${result.jwt}/${currentTimeStamp}`);
        }
      } catch (error) {
        //console.error(e);
        ToastManager.error(error.response.data.message || 'An error occurred during registration');
      }
    }
  };

  const handleBlur = async e => {
    setIsFormValid(validateForm());
    const {name, value} = e.target;
    setTouched({...touched, [name]: true});

    if (name === 'courseCode' && value.trim()) {
      setIsLoading(true);

      try {
        const result = await courseCode(value);

        if (result.length > 0) {
          setFormData({
            ...formData,
            schoolDistrict: result[0]?.district_name,
            schoolName: result[0]?.school_name,
            courseLearning: result[0]?.section_name,
            instructorName: result[0]?.instructor_name,
            districtId: result[0]?.district_id,
            schoolId: result[0]?.school_id,
            instructorId: result[0]?.instructor_id,
          });
          setErrors(prevErrors => {
            const {courseCode, ...rest} = prevErrors;
            return rest;
          });
        } else {
          setErrors(prevErrors => ({
            ...prevErrors,
            courseCode: 'Invalid course code.',
          }));
          setFormData(prevData => ({
            ...prevData,
            schoolDistrict: '',
            schoolName: '',
            courseLearning: '',
            instructorName: '',
            districtId: '',
            schoolId: '',
            instructorId: '',
          }));
        }
      } catch (error) {
        console.error(error);
        setErrors(prevErrors => ({
          ...prevErrors,
          courseCode: 'Invalid the course code.',
        }));
        setFormData(prevData => ({
          ...prevData,
          schoolDistrict: '',
          schoolName: '',
          courseLearning: '',
          instructorName: '',
          districtId: '',
          schoolId: '',
          instructorId: '',
        }));
      } finally {
        setIsLoading(false);
      }
    } else {
      setErrors(prevErrors => {
        const {courseCode, ...rest} = prevErrors;
        return rest;
      });
      setIsLoading(false);
    }
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        mt: 4,
      }}
    >
      <Box className="sign-up-form-wrapper">
        {isLoading && (
          <div className="overlay">
            <CircularProgress color="inherit" />
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <Typography className="lbl-signin">Sign Up</Typography>
          <Divider className="divider" />
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Typography className="form-lbl">
                <span className="required-mark">*</span>First Name
              </Typography>
              <TextField
              placeholder='First Name'
                className="form-field"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{maxLength: 100}}
                fullWidth
                required
                error={touched.firstName && !!errors.firstName}
                helperText={touched.firstName && errors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="form-lbl">
                <span className="required-mark">*</span>Last Name
              </Typography>
              <TextField
                placeholder='Last Name'
                className="form-field"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{maxLength: 100}}
                fullWidth
                required
                error={touched.lastName && !!errors.lastName}
                helperText={touched.lastName && errors.lastName}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography className="form-lbl">
                <span className="required-mark">*</span>Username
              </Typography>
              <TextField
                placeholder='Username'
                className="form-field username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{maxLength: 100}}
                fullWidth
                required
                error={touched.username && !!errors.username}
                helperText={touched.username && errors.username}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography className="form-lbl">
                <span className="required-mark">*</span>Password
              </Typography>
              <TextField
                placeholder='Password'
                className="form-field password-field"
                name="password"
                type={showPassword ? 'text' : 'password'}
                value={formData.password}
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{maxLength: 16}}
                fullWidth
                required
                error={touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />} 
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="form-lbl">
                <span className="required-mark">*</span>Confirm Password
              </Typography>
              <TextField
                placeholder='Confirm Password'
                className="form-field password-field"
                name="confirmPassword"
                type={showConfirmPassword ? 'text' : 'password'}
                value={formData.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{maxLength: 16}}
                fullWidth
                required
                error={touched.confirmPassword && !!errors.confirmPassword}
                helperText={touched.confirmPassword && errors.confirmPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className="form-lbl">
                <span className="required-mark">*</span>What is the course code?
              </Typography>
              <TextField
                placeholder='Course Code'
                className="form-field"
                name="courseCode"
                value={formData.courseCode}
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{maxLength: 50}}
                fullWidth
                required
                error={touched.courseCode && !!errors.courseCode}
                helperText={touched.courseCode && errors.courseCode}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography className="form-lbl">
                <span className="required-mark">*</span>What district is your school in?
              </Typography>
              <TextField
                placeholder='School District'
                className="form-field"
                name="schoolDistrict"
                required
                value={formData.schoolDistrict}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                error={touched.schoolDistrict && !!errors.schoolDistrict}
                helperText={touched.schoolDistrict && errors.schoolDistrict}
                disabled={true}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography className="form-lbl">
                <span className="required-mark">*</span>What is the name of your school?
              </Typography>
              <TextField
                placeholder='School Name'
                className="form-field"
                name="schoolName"
                value={formData.schoolName}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                fullWidth
                error={touched.schoolName && !!errors.schoolName}
                helperText={touched.schoolName && errors.schoolName}
                disabled={true}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography className="form-lbl">
                <span className="required-mark">*</span>What is the course your learning?
              </Typography>
              <TextField
                placeholder='Course Name'
                className="form-field"
                name="courseLearning"
                value={formData.courseLearning}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                required
                error={touched.courseLearning && !!errors.courseLearning}
                helperText={touched.courseLearning && errors.courseLearning}
                disabled={true}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography className="form-lbl">
                <span className="required-mark">*</span>What is the name of your teacher?
              </Typography>
              <TextField
                placeholder='Teacher Name'
                className="form-field"
                name="instructorName"
                value={formData.instructorName}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                required
                error={touched.instructorName && !!errors.instructorName}
                helperText={touched.instructorName && errors.instructorName}
                disabled={true}
              />
            </Grid>

            <Grid item xs={12}>
              <Button type="submit" size="large" variant="contained" color="primary" fullWidth disabled={!isFormValid}>
                Create Student Account
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

export default StudentRegistration;
