import React, {useState, useEffect} from 'react';
import {TextField, Button, FormControl, Select, MenuItem, Grid, Typography, Box} from '@mui/material';
import './Registeration.scss';
import Divider from '@mui/material/Divider';
import {getDistrict, instituteCourse, instructorRegistrationFormn} from 'service/signup';
import {FormHelperText, IconButton, InputAdornment} from '@material-ui/core';
import {ToastManager} from 'components/ToastManager/ToastManager';
import {useHistory} from 'react-router-dom';
import {Visibility, VisibilityOff} from '@material-ui/icons';
import { email_regex, first_name_regx, last_name_regx, numeric_regex, set_password_regex, phone_regex, school_name_regex } from 'util/Extra';

const RegistrationForm = ({setIsRegistrationComplete, isRegistrationComplete}) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
    schoolName: '',
    schoolDistrict: '',
    courseTaught: [],
    country: '',
    state: '',
    city: '',
    zipCode: '',
    status: '',
    studentCount: '',
  });

  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [courses, setCourses] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const history = useHistory();

  const validateForm = () => {
    let newErrors = {};

    if (!formData.firstName.trim()) {
      newErrors.firstName = 'First Name is required';
    } else if (!first_name_regx.test(formData.firstName)) {
      newErrors.firstName = 'First Name should be between 3-100 letters only. Numbers and special characters are not accepted.';
    }
    
    if (!formData.lastName.trim()) {
      newErrors.lastName = 'Last Name is required';
    } else if (!last_name_regx.test(formData.lastName)) {
      newErrors.lastName = 'Last Name should be between 3-100 letters only. Numbers and special characters are not accepted.';
    } 
    if (!formData.email.trim() || !email_regex.test(formData.email)) newErrors.email = 'Valid Email is required';
    if (!formData.phone.trim() || !phone_regex.test(formData.phone)) {
      newErrors.phone = 'Valid 10-digit Phone Number is required';
    }


    if (!formData.password) {
      newErrors.password = 'Password is required';
    } else if (!set_password_regex.test(formData.password)) {
      newErrors.password = 'Passwords must contain at least 8 alphanumerical characters and should be include one small letter, one caps letter.';
    } 

    if (formData.password !== formData.confirmPassword) newErrors.confirmPassword = 'Passwords must match';
    //if (!formData.schoolName.trim()) newErrors.schoolName = 'School Name is required';
    if (!formData.schoolName.trim()) {
      newErrors.schoolName = 'School Name is required';
    } else if (!school_name_regex.test(formData.schoolName)) {
      newErrors.schoolName = 'School Name must be between 3 and 100 characters.';
    }

    if (!formData.schoolDistrict) {
      newErrors.schoolDistrict = 'School District is required';
    }

    if (!formData.courseTaught) {
      newErrors.courseTaught = 'Please select a course you are teaching';
    }

    if (!formData.studentCount) {
      newErrors.studentCount = 'Student count is required.';
    } else if (!numeric_regex.test(formData.studentCount)) {
      newErrors.studentCount = 'Please enter a positive whole number for student count.';
    } else if (parseInt(formData.studentCount, 10) < 1) {
      newErrors.studentCount = 'Student count should be greater than 0.';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const formatPhoneNumber = phone => {
    if (phone.length < 4) return phone;
    if (phone.length < 7) return `(${phone.slice(0, 3)}) ${phone.slice(3)}`;
    return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedCourses = await instituteCourse();
        setCourses(fetchedCourses);

        const districtResponse = await getDistrict();
        setDistricts(districtResponse);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleChange = e => {
    const {name, value} = e.target;

    if (name === 'phone') {
      const input = value.replace(/\D/g, '');
      const formattedPhone = formatPhoneNumber(input);
      setFormData(prevData => ({...prevData, [name]: formattedPhone}));
    } else if (name === 'courseTaught') {
      const selectedValues = e.target.value;
      setFormData(prevData => ({...prevData, [name]: selectedValues}));
      setIsFormValid(validateForm());
    } else if (name === 'schoolDistrict') {
      setFormData(prevData => ({...prevData, [name]: value}));
      setIsFormValid(validateForm());
    } else {
      setFormData(prevData => ({...prevData, [name]: value}));
      setIsFormValid(validateForm());
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (isFormValid) {
      const instructorRegisterData = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        phone:formData.phone.replace(/\D/g, ''),
        password: formData.password,
        confirm_password: formData.confirmPassword,
        school_name: formData.schoolName,
        district_name: formData.schoolDistrict,
        courses: formData.courseTaught,
      };
      if (formData.country) {
        instructorRegisterData.country_id = formData.country;
      }

      if (formData.state) {
        instructorRegisterData.state = formData.state;
      }

      if (formData.city) {
        instructorRegisterData.city = formData.city;
      }

      if (formData.zipCode) {
        instructorRegisterData.zip = formData.zipCode;
      }

      if (formData.studentCount) {
        instructorRegisterData.seats = formData.studentCount;
      }
      try {
        const result = await instructorRegistrationFormn(instructorRegisterData);
        if (result) {
          history.push('/signup');
          setIsRegistrationComplete(true);
          ToastManager.success(result.message || 'Registration successful!');
        }
      } catch (error) {
        ToastManager.error(error.response.data.message || 'An error occurred during registration');
      }
    }
  };

  const handleBlur = e => {
    setIsFormValid(validateForm());
    setTouched({...touched, [e.target.name]: true});
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        mt: 2,
      }}
    >
      <Box className="sign-up-form-wrapper">
        <form onSubmit={handleSubmit}>
          <Typography className="lbl-signin">Sign Up</Typography>
          <Divider className="divider" />

          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Typography className="form-lbl">
                <span className="required-mark">*</span>First Name
              </Typography>
              <TextField
                placeholder="First Name"
                className="form-field"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{maxLength: 100}}
                fullWidth
                required
                error={touched.firstName && !!errors.firstName}
                helperText={touched.firstName && errors.firstName}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography className="form-lbl">
                <span className="required-mark">*</span>Last Name
              </Typography>
              <TextField
                placeholder="Last Name"
                className="form-field"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{maxLength: 100}}
                fullWidth
                required
                error={touched.lastName && !!errors.lastName}
                helperText={touched.lastName && errors.lastName}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography className="form-lbl">
                <span className="required-mark">*</span>Email
              </Typography>
              <TextField
                placeholder="Email"
                className="form-field username"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{maxLength: 100}}
                fullWidth
                required
                error={touched.email && !!errors.email}
                helperText={touched.email && errors.email}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography className="form-lbl">
                <span className="required-mark">*</span>Phone
              </Typography>
              <TextField
                placeholder="Phone"
                className="form-field"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                required
                inputProps={{maxLength: 14}}
                error={touched.phone && !!errors.phone}
                helperText={touched.phone && errors.phone}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography className="form-lbl">
                <span className="required-mark">*</span>Password
              </Typography>
              <TextField
                placeholder="Password"
                className="form-field password-field"
                name="password"
                type={showPassword ? 'text' : 'password'}
                value={formData.password}
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{maxLength: 16}}
                fullWidth
                required
                error={touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography className="form-lbl">
                <span className="required-mark">*</span>Confirm Password
              </Typography>
              <TextField
                placeholder="Confirm Password"
                className="form-field password-field"
                name="confirmPassword"
                type={showConfirmPassword ? 'text' : 'password'}
                value={formData.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{maxLength: 16}}
                fullWidth
                required
                error={touched.confirmPassword && !!errors.confirmPassword}
                helperText={touched.confirmPassword && errors.confirmPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowConfirmPassword} edge="end">
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography className="form-lbl">
                <span className="required-mark">*</span>What is the name of your school?
              </Typography>
              <TextField
                placeholder="School Name"
                className="form-field"
                name="schoolName"
                value={formData.schoolName}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                required
                error={touched.schoolName && !!errors.schoolName}
                helperText={touched.schoolName && errors.schoolName}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography className="form-lbl">
                <span className="required-mark">*</span>What district is your school in?
              </Typography>
              <FormControl fullWidth>
                <Select
                  className="form-field"
                  name="schoolDistrict"
                  value={formData.schoolDistrict || ''}
                  onChange={handleChange}
                  displayEmpty
                  renderValue={selected => {
                    if (selected.length === 0) {
                      return (
                        <span
                          style={{
                            color: 'rgba(117, 117, 117, 0.45)',
                            fontSize: '14px',
                          }}
                        >
                          Select School District
                        </span>
                      );
                    }
                    return selected;
                  }}
                  sx={{
                    '& .MuiSelect-select.Mui-selected': {
                      color: 'inherit',
                    },
                  }}
                >
                  {districts.length > 0 ? (
                    districts.map(district => (
                      <MenuItem key={district.dist_id} value={district.name}>
                        {district.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No districts available</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography className="form-lbl">
                <span className="required-mark">*</span>What is the course you're teaching?
              </Typography>
              <FormControl fullWidth error={touched.courseTaught && !!errors.courseTaught}>
                <Select
                  multiple
                  className="form-field"
                  name="courseTaught"
                  value={formData.courseTaught || []}
                  onChange={handleChange}
                  displayEmpty
                  renderValue={selected => {
                    if (selected.length === 0) {
                      return (
                        <span
                          style={{
                            color: 'rgba(117, 117, 117, 0.45)',
                            fontSize: '14px',
                          }}
                        >
                          Select Courses
                        </span>
                      );
                    }
                    const selectedCourseNames = courses.filter(course => selected.includes(course.course_id)).map(course => course.course_name);
                    return selectedCourseNames.join(', ');
                  }}
                >
                  {courses.length > 0 ? (
                    courses.map(course => (
                      <MenuItem key={course.course_id} value={course.course_id}>
                        {course.course_name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No courses available</MenuItem>
                  )}
                </Select>
                {touched.courseTaught && errors.courseTaught && <FormHelperText>{errors.courseTaught}</FormHelperText>}
              </FormControl>
            </Grid>

            {/* <Grid item xs={12} sm={6}>
              <Typography className="form-lbl">Country</Typography>
              <TextField
                placeholder="Country"
                className="form-field"
                name="country"
                value={formData.country}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.country && !!errors.country}
                helperText={touched.country && errors.country}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography className="form-lbl">State</Typography>
              <TextField
                placeholder="State"
                className="form-field"
                name="state"
                value={formData.state}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.state && !!errors.state}
                helperText={touched.state && errors.state}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography className="form-lbl">City</Typography>
              <TextField
                placeholder="City"
                className="form-field"
                name="city"
                value={formData.city}
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{maxLength: 50}}
                error={touched.city && !!errors.city}
                helperText={touched.city && errors.city}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography className="form-lbl">Zip Code</Typography>
              <TextField
                className="form-field"
                placeholder="zip code"
                name="zipCode"
                value={formData.zipCode}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.zipCode && !!errors.zipCode}
                helperText={touched.zipCode && errors.zipCode}
                inputProps={{maxLength: 5}}
                fullWidth
              />
            </Grid> */}

            <Grid item xs={12}>
              <Typography className="form-lbl"><span className="required-mark">*</span>Approximately how many students do you anticipate will enroll in the course?</Typography>
              <TextField
                placeholder="Student Count"
                className="form-field"
                name="studentCount"
                type="number"
                value={formData.studentCount}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.studentCount && !!errors.studentCount}
                helperText={touched.studentCount && errors.studentCount}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Button type="submit" size="large" variant="contained" color="primary" fullWidth disabled={!isFormValid}>
                Create Instructor Account
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

export default RegistrationForm;
