import {Link} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {isEmpty} from 'lodash';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import RegistrationForm from 'components/RegisterationForm/RegistrationForm';
import StudentRegistration from 'components/RegisterationForm/StudentRegisteration';
import RegistrationSelectRole from 'components/RegisterationForm/roleCard';
import RegistrationConfirmation from 'components/RegisterationForm/RagistrationConfirmation';
import {useHistory, useLocation} from 'react-router-dom';

const Signup = ({props}) => {
  const {signinStatus, authUser, userRole} = useSelector(state => state.auth);
  const countConfigCall = sessionStorage.getItem('countConfigCall');
  const [selectedRole, setSelectedRole] = useState(null);
  const [isRegistrationComplete, setIsRegistrationComplete] = useState(false);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (authUser !== null && signinStatus && userRole !== null) {
      history.push(`/app`);
    } else {
      if (countConfigCall === 0) {
        props.setPconfig({});
        sessionStorage.setItem('countConfigCall', countConfigCall + 1);
      }
    }
    const params = new URLSearchParams(location.search);
    const currentType = params.get('type');
    if (currentType) {
      setSelectedRole(currentType || null);
    }
  }, [authUser, signinStatus, userRole, countConfigCall, location.search, props , history]);

  const handleRoleSelect = role => {
    setSelectedRole(role);
    const params = new URLSearchParams(location.search);
    params.set('type', role);
    history.push({search: params.toString()});
  };

  return (
    <>
      <div className="app-container">
        <div className="app-main-container">
          <div className="signup-page">
            <header>
              <div className="logo-wrap">
                <img className="" alt="logo" src={'https://lmsassets.cdn.higherl.com/23/3/assets/images/tdn_white_logo.png'} />
              </div>
              <Link className="back-to-login" href="/signin">
                <ArrowBackIcon />
                Back to Log In
              </Link>
            </header>

            <div>
              {isEmpty(selectedRole) && !isRegistrationComplete ? (
                <RegistrationSelectRole onSelectRole={handleRoleSelect} />
              ) : (
                <div className="role-content">
                  {selectedRole === 'instructor' && !isRegistrationComplete && (
                    <RegistrationForm setIsRegistrationComplete={setIsRegistrationComplete} isRegistrationComplete={isRegistrationComplete} />
                  )}
                  {selectedRole === 'student' && !isRegistrationComplete && <StudentRegistration setIsRegistrationComplete={setIsRegistrationComplete} />}
                  {isRegistrationComplete && <RegistrationConfirmation />}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;