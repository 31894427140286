import * as coursesType from '../../constants/ActionTypes';
import {all, fork, put, takeLatest, select, takeEvery, call, take} from 'redux-saga/effects';
import axios from 'util/Api';
import {
  amerikoolerFetchCourseDataLoader,
  amerikoolerSaveCourseData,
  amerikoolerSaveCurrentUnit,
  amerikoolerSaveCurrentLesson,
  amerikoolerSaveCurrentResource,
  amerikoolerNextStepLoader,
  amerikoolerPreviousStepLoader,
  amerikoolerSaveHmiData,
  amerikoolerSaveVrData,
  amerikoolerSavePdfData,
  amerikoolerSaveVideoData,
  amerikoolerSaveContentHtmlData,
  amerikoolerSaveScormData,
  amerikoolerSaveFirstandLastResourceId,
  amerikoolerUpdateCurrentUnit,
  amerikoolerSaveHmiSteps,
  amerikoolerLockCourseBtnFunc,
  amerikoolerCertificateLoader,
  amerikoolerCourseLoader,
  akFetchVrResourceCompleteData,
  akShowHideCertificateModal,
  akSavehVideoPlayedSeconds,
  akSavehUpdatedVideoPlayedSeconds,
  akSaveSingleScormResourcePorgress,
  akSingleScormResourceCertificateButtonEnable,
  updateUserInfoTimeLoader,
  updateUserInfoTime,
} from '../actions/AmerikoolerCourse';
import {errorHandler} from '../actions/Error';
import {HMI, VR, CONTENT_HTML, FILE, NEXT, PREVIOUS, VR_STEPS_TYPE_CONTENT, VR_STEPS_TYPE_RESOURSE, PUBLISH, PENDING} from '../../constants/Constants';
import {nextPreviousArrayValue, laravelEchoOptions, getDefaultDomainDetails} from '../../util/Extra';
import {findKey, isUndefined, map, isNull, isEmpty, includes} from 'lodash';
import {ToastManager} from '../../components/ToastManager/ToastManager';
import Echo from 'laravel-echo';
import {eventChannel} from 'redux-saga';
import {push} from 'connected-react-router';
import {NotificationManager} from 'react-notifications';

var arr = [];
// amerikooler courses certificate fetch worker
export function* akFetchCertificateWorker({payload}) {
  try {
    yield put(amerikoolerCertificateLoader(true));
    const {email} = {...payload};
    const reduxState = yield select();
    const {amerikoolerCourse} = reduxState;
    const {akCourseData} = amerikoolerCourse;
    const {course_section} = !!akCourseData && akCourseData[0];
    const certificateDetails = yield axios.post(`/request-certificate-student`, {
      section_id: course_section,
      share_certificate_to_email: email,
    });
    const {status, data} = !!certificateDetails && certificateDetails;
    const {certificate_temp_link} = !!data && data;
    if (status === 200 && !isEmpty(certificate_temp_link)) {
      const certificate = yield fetch(certificate_temp_link);
      const blob = yield certificate.blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'Amerikooler_Completion_Certificate.pdf';
      link.click();
      window.open(certificate_temp_link, '_blank');
      yield put(amerikoolerCertificateLoader(false));
      yield put(akShowHideCertificateModal(false));
    }
  } catch (error) {
    // yield put(errorHandler(error));
    yield put(errorHandler({error: error, page: ''}));
    yield put(amerikoolerCertificateLoader(false));
  }
}
// amerikooler course fetch certificate watcher
export function* akFetchCertificateWatcher() {
  yield takeLatest(coursesType.AK_FETCH_CERTIFICATE, akFetchCertificateWorker);
}

// TDN courses details fetch worker
export function* tdnFetchCoursesDataWorker() {
  try {
    const reduxState = yield select();
    const {auth} = reduxState;
    const {ssoLoginDetails} = auth;
    yield put(amerikoolerFetchCourseDataLoader(true));
    const conditionalUrl = !!ssoLoginDetails?.section_id ? '/course-lesson-resource?section_id='+ssoLoginDetails?.section_id : '/course-lesson-resource';
    const coursesDetails = yield axios.get(conditionalUrl);
    if (coursesDetails.status === 200) {
      yield put(amerikoolerSaveCourseData(coursesDetails.data));
    }
    yield put(amerikoolerFetchCourseDataLoader(false));
  } catch (error) {
    yield put(amerikoolerFetchCourseDataLoader(false));
    // yield put(errorHandler(error));
    yield put(errorHandler({error: error, page: ''}));
  }
}
// amerikooler course fetch data watcher
export function* tdnFetchCoursesDataWatcher() {
  yield takeLatest(coursesType.TDN_FETCH_COURSE_DATA, tdnFetchCoursesDataWorker);
}

// amerikooler courses details fetch worker
export function* tdnFetchResourceDataWorker({payload: {course_section}}) {
  try {
    const reduxState = yield select();
    const {amerikoolerCourse} = reduxState;
    const {akCourseData} = amerikoolerCourse;

    if (!isEmpty(akCourseData)) {
      // const data = amerikoolerCourseData[0];
      const data = akCourseData.find(item => item.course_section === course_section);
      const {units} = data;
      const currentUnit = units[0];
      const {lessons} = currentUnit;
      let currentLesson = null;
      let currentResource = null;
      let firstResourceId = null;
      let lastResourceId = null;
      if (!isEmpty(currentUnit) && !isEmpty(currentUnit.lessons)) {
        map(currentUnit.lessons, lesson => {
          map(lesson.resources, res => {
            if (isNull(firstResourceId)) {
              firstResourceId = res.resource_id;
            }
            lastResourceId = res.resource_id;
            if (res.last_visited_resource && isEmpty(currentResource)) {
              currentLesson = lesson;
              currentResource = res;
            }
          });
        });
      }
      if (isEmpty(currentLesson)) {
        currentLesson = lessons[0];
        const {resources} = currentLesson;
        currentResource = resources[0];
      }
      const {resource_type, resource_id, content_status} = !!currentResource && currentResource;
      yield put(amerikoolerSaveFirstandLastResourceId({firstResourceId, lastResourceId}));
      yield put(amerikoolerSaveCurrentResource(currentResource));
      // yield put(amerikoolerSaveCourseData(amerikoolerCourseData));
      yield put(amerikoolerSaveCurrentUnit(currentUnit));
      yield put(amerikoolerSaveCurrentLesson(currentLesson));
      // if resource is publish
      if (content_status === PUBLISH) {
        // if resource type is scorm
        if (resource_type === 'Web Resource') {
          const resourceDetailsResponse = yield axios.get(`/resource/${resource_id}`);
          const resourceDetails = resourceDetailsResponse.data[0];
          if (resourceDetailsResponse.status === 200 && !isEmpty(resourceDetails)) {
            // socket connections
            yield put(akFetchVrResourceCompleteData());
            const {steps, resource_id} = !!resourceDetails && resourceDetails;
            const scormData = {
              ...steps[0],
              resourceDetails,
            };
            yield put(amerikoolerSaveScormData({...scormData}));
            // save progress of resource when course has only one resource and resource type is scorm
            if (firstResourceId === lastResourceId) {
              const reduxState = yield select();
              const {auth} = reduxState;
              const {userId, studentId} = auth;
              const {ssoLoginDetails, userRole} = auth;
              const student_Id = !!ssoLoginDetails ? ssoLoginDetails?.studentId : studentId;
              const sendUserOrStudentId = userRole === 'instructor' ? student_Id : userId;
              const getCmiData = yield axios.get(`scorm-logger/${sendUserOrStudentId}/section-resource-id/${resource_id}/get-log`);
              var {score} = getCmiData.data;
              if (!isEmpty(score)) {
                score = JSON.parse(score);
                const {lesson_status} = score;
                const s = !!score.score && score.score;
                const {raw} = !!s && s;
                if (!isEmpty(raw)) {
                  yield put(akSaveSingleScormResourcePorgress(parseFloat(raw)));
                }
                if (!!lesson_status && (lesson_status === 'passed' || lesson_status === 'completed')) {
                  yield put(akSingleScormResourceCertificateButtonEnable(false));
                }
              }
            }
          }
        }

        // if resource type is File
        else if (resource_type === FILE) {
          const resourceDetailsResponse = yield axios.get(`/resource/${resource_id}`);
          const resourceDetails = resourceDetailsResponse.data[0];
          if (resourceDetailsResponse.status === 200 && !isEmpty(resourceDetails)) {
            // socket connections
            yield put(akFetchVrResourceCompleteData());
            const {steps, content_type_id} = !!resourceDetails && resourceDetails;
            const resData = {
              ...steps[0],
            };
            /* if resource type is video */
            if (content_type_id === 'FILE') {
              yield put(amerikoolerSaveVideoData({...resData}));
              yield put(amerikoolerSavePdfData(null));
            }

            //if resource type is pdf
            else if (content_type_id === 'PDF') {
              yield put(amerikoolerSavePdfData({...resData}));
              yield put(amerikoolerSaveVideoData(null));
            }
          }
        }

        // if resource type is VR
        else if (resource_type === VR) {
          const resourceDetailsResponse = yield axios.get(`/resource/${resource_id}`);
          const resourceDetails = resourceDetailsResponse.data[0];

          if (resourceDetailsResponse.status === 200 && !isEmpty(resourceDetails)) {
            const {steps, steps_type} = !!resourceDetails && resourceDetails;
            // socket connections
            yield put(akFetchVrResourceCompleteData());
            let getGemetriViewUrl = null;
            // when vr resource has only one child steps
            if (steps_type === VR_STEPS_TYPE_CONTENT) {
              // for gemetri view url api
              getGemetriViewUrl = yield axios.get(`/gmetri/view/${resource_id}`);
            }
            // when vr resource has multiple child resources
            else if (steps_type === VR_STEPS_TYPE_RESOURSE) {
              const {id} = steps[0];
              // for gemetri view url api
              getGemetriViewUrl = yield axios.get(`/gmetri/view/${id}`);
            }
            const vrDatas = {...steps[0], url: !!getGemetriViewUrl && getGemetriViewUrl.data.auth_url ? getGemetriViewUrl.data.auth_url : ''};
            yield put(amerikoolerSaveVrData({...vrDatas}));
          }
        }

        // if resource type is HMI
        else if (resource_type === HMI) {
          try {
            const resourceDetailsResponse = yield axios.get(`/resource/${resource_id}`);
            const resourceDetails = resourceDetailsResponse.data[0];

            if (resourceDetailsResponse.status === 200 && !isEmpty(resourceDetails)) {
              // socket connections
              yield put(akFetchVrResourceCompleteData());
              const hmiResponceData = yield axios.get(`/jsat/init/${resource_id}`);

              if (hmiResponceData.status === 200) {
                const {steps} = !!resourceDetails && resourceDetails;
                const hmiData = {
                  ...steps[0],
                };
                yield put(amerikoolerSaveHmiData({...hmiData}));
                yield put(amerikoolerSaveHmiSteps({hmiSteps: hmiResponceData.data}));
              }
            }
          } catch (error) {
            yield put(amerikoolerFetchCourseDataLoader(false));
            // yield put(errorHandler(error));
            yield put(errorHandler({error: error, page: ''}));
          }
        }

        // if resource type is content html
        else if (resource_type === CONTENT_HTML) {
          // socket connections
          yield put(akFetchVrResourceCompleteData());
          yield put(amerikoolerSaveContentHtmlData({...currentResource}));
        }
      }
      yield put(push(`course`));
    }

    //yield put(amerikoolerFetchCourseDataLoader(true));

    yield put(amerikoolerFetchCourseDataLoader(false));
  } catch (error) {
    yield put(amerikoolerFetchCourseDataLoader(false));
    // yield put(errorHandler(error));
    yield put(errorHandler({error: error, page: ''}));
  }
}
// amerikooler course fetch data watcher
export function* tdnFetchResourceDataWatcher() {
  yield takeLatest(coursesType.TDN_FETCH_RESOURCE_DATA, tdnFetchResourceDataWorker);
}

// amerikooler courses details fetch worker
export function* tdnLaunchDataWorker({payload: {course_section}}) {
  try {
    const reduxState = yield select();
    const {amerikoolerCourse} = reduxState;
    const {akCourseData} = amerikoolerCourse;

    if (!isEmpty(akCourseData)) {
      // const data = amerikoolerCourseData[0];
      const data = akCourseData.find(item => item.course_section === course_section);
      const {units} = data;
      const currentUnit = units[0];
      const {lessons} = currentUnit;
      let currentLesson = null;
      let currentResource = null;
      let firstResourceId = null;
      let lastResourceId = null;
      if (!isEmpty(currentUnit) && !isEmpty(currentUnit.lessons)) {
        map(currentUnit.lessons, lesson => {
          map(lesson.resources, res => {
            if (isNull(firstResourceId)) {
              firstResourceId = res.resource_id;
            }
            lastResourceId = res.resource_id;
            if (res.last_visited_resource && isEmpty(currentResource)) {
              currentLesson = lesson;
              currentResource = res;
            }
          });
        });
      }
      if (isEmpty(currentLesson)) {
        currentLesson = lessons[0];
        const {resources} = currentLesson;
        currentResource = resources[0];
      }
      const {resource_type, resource_id, content_status} = !!currentResource && currentResource;
      yield put(amerikoolerSaveFirstandLastResourceId({firstResourceId, lastResourceId}));
      yield put(amerikoolerSaveCurrentResource(currentResource));
      // yield put(amerikoolerSaveCourseData(amerikoolerCourseData));
      yield put(amerikoolerSaveCurrentUnit(currentUnit));
      yield put(amerikoolerSaveCurrentLesson(currentLesson));
      // if resource is publish
      if (content_status === PUBLISH) {
        // if resource type is scorm
        if (resource_type === 'Web Resource') {
          const resourceDetailsResponse = yield axios.get(`/resource/${resource_id}`);
          const resourceDetails = resourceDetailsResponse.data[0];
          if (resourceDetailsResponse.status === 200 && !isEmpty(resourceDetails)) {
            // socket connections
            yield put(akFetchVrResourceCompleteData());
            const {steps, resource_id} = !!resourceDetails && resourceDetails;
            const scormData = {
              ...steps[0],
              resourceDetails,
            };
            yield put(amerikoolerSaveScormData({...scormData}));
            // save progress of resource when course has only one resource and resource type is scorm
            if (firstResourceId === lastResourceId) {
              const reduxState = yield select();
              const {auth} = reduxState;
              const {userId, studentId} = auth;
              const {ssoLoginDetails, userRole} = auth;
              const student_Id = !!ssoLoginDetails ? ssoLoginDetails?.studentId : studentId;
              const sendUserOrStudentId = userRole === 'instructor' ? student_Id : userId;
              const getCmiData = yield axios.get(`scorm-logger/${sendUserOrStudentId}/section-resource-id/${resource_id}/get-log`);
              var {score} = getCmiData.data;
              if (!isEmpty(score)) {
                score = JSON.parse(score);
                const {lesson_status} = score;
                const s = !!score.score && score.score;
                const {raw} = !!s && s;
                if (!isEmpty(raw)) {
                  yield put(akSaveSingleScormResourcePorgress(parseFloat(raw)));
                }
                if (!!lesson_status && (lesson_status === 'passed' || lesson_status === 'completed')) {
                  yield put(akSingleScormResourceCertificateButtonEnable(false));
                }
              }
            }
          }
        }

        const reduxState = yield select();
        const {amerikoolerCourse, auth} = reduxState;
        const {akScormData} = amerikoolerCourse;
        const {token, userId, authUser, ssoLoginDetails, studentId, userRole} = auth;
        const {url, resourceDetails} = !!akScormData && akScormData;
        const {content_version} = !!resourceDetails && resourceDetails;
        const student_Id = !!ssoLoginDetails ? ssoLoginDetails?.studentId : studentId;
        const sendUserOrStudentId = userRole === 'instructor' ? student_Id : userId;
        const apiKey = getDefaultDomainDetails().api_key;
        const apiUrl = getDefaultDomainDetails().server_url;
        const scormLauncherUrlLink = getDefaultDomainDetails().scorm_launcher_url_link;
        const instituteId = getDefaultDomainDetails().institute_key;

        const targetUrl = `${scormLauncherUrlLink}?launchUrl=${url}&sectionId=${course_section}&studentId=${btoa(sendUserOrStudentId)}&resourceId=${btoa(
          resource_id,
        )}&version=${content_version}&token=${token}&studentName=${authUser}&apiUrl=${apiUrl}&apiKey=${apiKey}&instituteId=${instituteId}`;

        const openedWindow = window.open(targetUrl, '_blank', 'location=yes,height=1000,width=1000,scrollbars=yes,status=yes');
        if (!openedWindow) {
          NotificationManager.error('Please Enable The PopUp And Relaunch the Course');
        }
        if (!isEmpty(currentResource) && !isEmpty(akCourseData)) {
          // call progress API when component is mounted for resume resource
          yield put(
            updateUserInfoTime({
              section_id: course_section,
              resource_id: resource_id,
              type: 'course',
              offset_time: 1,
            }),
          );
          yield delay(20000);
          yield call(callSelfOnTimer, {course_section: course_section, resource_id: resource_id});
        }
      }
    }

    //yield put(amerikoolerFetchCourseDataLoader(true));

    yield put(amerikoolerFetchCourseDataLoader(false));
  } catch (error) {
    yield put(amerikoolerFetchCourseDataLoader(false));
    // yield put(errorHandler(error));
    yield put(errorHandler({error: error, page: ''}));
  }
}

const delay = ms => new Promise(res => setTimeout(res, ms));
function* callSelfOnTimer({course_section, resource_id}) {
  // call in 20 seconds
  if (course_section && resource_id) {
    yield put(
      updateUserInfoTime({
        section_id: course_section,
        resource_id: resource_id,
        type: 'course',
      }),
    );
    yield delay(20000);
    yield call(callSelfOnTimer, {course_section: course_section, resource_id: resource_id});
  }
}

// amerikooler course fetch data watcher
export function* tdnLaunchDataWatcher() {
  yield takeLatest(coursesType.TDN_LAUNCH_DATA, tdnLaunchDataWorker);
}

// amerikooler courses details fetch worker
export function* akFetchCoursesDataWorker() {
  try {
    const reduxState = yield select();
    const {auth} = reduxState;
    const {ssoLoginDetails} = auth;
    yield put(amerikoolerFetchCourseDataLoader(true));
    const conditionalUrl = !!ssoLoginDetails?.section_id ? '/course-lesson-resource?section_id=' + ssoLoginDetails?.section_id : '/course-lesson-resource';
    const coursesDetails = yield axios.get(conditionalUrl);
    if (coursesDetails.status === 200 && !isEmpty(coursesDetails.data[0])) {
      // const data = amerikoolerCourseData[0];
      const data = coursesDetails.data[0];

      const {units} = data;
      const currentUnit = units[0];
      const {lessons} = currentUnit;
      let currentLesson = null;
      let currentResource = null;
      let firstResourceId = null;
      let lastResourceId = null;
      if (!isEmpty(currentUnit) && !isEmpty(currentUnit.lessons)) {
        map(currentUnit.lessons, lesson => {
          map(lesson.resources, res => {
            if (isNull(firstResourceId)) {
              firstResourceId = res.resource_id;
            }
            lastResourceId = res.resource_id;
            if (res.last_visited_resource && isEmpty(currentResource)) {
              currentLesson = lesson;
              currentResource = res;
            }
          });
        });
      }
      if (isEmpty(currentLesson)) {
        currentLesson = lessons[0];
        const {resources} = currentLesson;
        currentResource = resources[0];
      }
      const {resource_type, resource_id, content_status} = !!currentResource && currentResource;
      yield put(amerikoolerSaveFirstandLastResourceId({firstResourceId, lastResourceId}));
      yield put(amerikoolerSaveCurrentResource(currentResource));
      yield put(amerikoolerSaveCourseData(coursesDetails.data));
      // yield put(amerikoolerSaveCourseData(amerikoolerCourseData));
      yield put(amerikoolerSaveCurrentUnit(currentUnit));
      yield put(amerikoolerSaveCurrentLesson(currentLesson));
      // if resource is publish
      if (content_status === PUBLISH) {
        // if resource type is scorm
        if (resource_type === 'Web Resource' && !!sessionStorage.getItem('Authorization')) {
          const resourceDetailsResponse = yield axios.get(`/resource/${resource_id}`);
          const resourceDetails = resourceDetailsResponse.data[0];
          if (resourceDetailsResponse.status === 200 && !isEmpty(resourceDetails)) {
            // socket connections
            yield put(akFetchVrResourceCompleteData());
            const {steps, resource_id} = !!resourceDetails && resourceDetails;
            const scormData = {
              ...steps[0],
              resourceDetails,
            };
            yield put(amerikoolerSaveScormData({...scormData}));
            // save progress of resource when course has only one resource and resource type is scorm
            if (firstResourceId === lastResourceId) {
              const reduxState = yield select();
              const {auth} = reduxState;
              const {userId, studentId} = auth;
              const {ssoLoginDetails, userRole} = auth;
              const student_Id = !!ssoLoginDetails ? ssoLoginDetails?.studentId : studentId;
              const sendUserOrStudentId = userRole === 'instructor' ? student_Id : userId;
              const getCmiData = yield axios.get(`scorm-logger/${sendUserOrStudentId}/section-resource-id/${resource_id}/get-log`);
              var {score} = getCmiData.data;
              if (!isEmpty(score)) {
                score = JSON.parse(score);
                const {lesson_status} = score;
                const s = !!score.score && score.score;
                const {raw} = !!s && s;
                if (!isEmpty(raw)) {
                  yield put(akSaveSingleScormResourcePorgress(parseFloat(raw)));
                }
                if (!!lesson_status && (lesson_status === 'passed' || lesson_status === 'completed')) {
                  yield put(akSingleScormResourceCertificateButtonEnable(false));
                }
              }
            }
          }
        }

        // if resource type is File
        else if (resource_type === FILE) {
          const resourceDetailsResponse = yield axios.get(`/resource/${resource_id}`);
          const resourceDetails = resourceDetailsResponse.data[0];
          if (resourceDetailsResponse.status === 200 && !isEmpty(resourceDetails)) {
            // socket connections
            yield put(akFetchVrResourceCompleteData());
            const {steps, content_type_id} = !!resourceDetails && resourceDetails;
            const resData = {
              ...steps[0],
            };
            /* if resource type is video */
            if (content_type_id === 'FILE') {
              yield put(amerikoolerSaveVideoData({...resData}));
              yield put(amerikoolerSavePdfData(null));
            }

            //if resource type is pdf
            else if (content_type_id === 'PDF') {
              yield put(amerikoolerSavePdfData({...resData}));
              yield put(amerikoolerSaveVideoData(null));
            }
          }
        }

        // if resource type is VR
        else if (resource_type === VR) {
          const resourceDetailsResponse = yield axios.get(`/resource/${resource_id}`);
          const resourceDetails = resourceDetailsResponse.data[0];

          if (resourceDetailsResponse.status === 200 && !isEmpty(resourceDetails)) {
            const {steps, steps_type} = !!resourceDetails && resourceDetails;
            // socket connections
            yield put(akFetchVrResourceCompleteData());
            let getGemetriViewUrl = null;
            // when vr resource has only one child steps
            if (steps_type === VR_STEPS_TYPE_CONTENT) {
              // for gemetri view url api
              getGemetriViewUrl = yield axios.get(`/gmetri/view/${resource_id}`);
            }
            // when vr resource has multiple child resources
            else if (steps_type === VR_STEPS_TYPE_RESOURSE) {
              const {id} = steps[0];
              // for gemetri view url api
              getGemetriViewUrl = yield axios.get(`/gmetri/view/${id}`);
            }
            const vrDatas = {...steps[0], url: !!getGemetriViewUrl && getGemetriViewUrl.data.auth_url ? getGemetriViewUrl.data.auth_url : ''};
            yield put(amerikoolerSaveVrData({...vrDatas}));
          }
        }

        // if resource type is HMI
        else if (resource_type === HMI) {
          try {
            const resourceDetailsResponse = yield axios.get(`/resource/${resource_id}`);
            const resourceDetails = resourceDetailsResponse.data[0];

            if (resourceDetailsResponse.status === 200 && !isEmpty(resourceDetails)) {
              // socket connections
              yield put(akFetchVrResourceCompleteData());
              const hmiResponceData = yield axios.get(`/jsat/init/${resource_id}`);

              if (hmiResponceData.status === 200) {
                const {steps} = !!resourceDetails && resourceDetails;
                const hmiData = {
                  ...steps[0],
                };
                yield put(amerikoolerSaveHmiData({...hmiData}));
                yield put(amerikoolerSaveHmiSteps({hmiSteps: hmiResponceData.data}));
              }
            }
          } catch (error) {
            yield put(amerikoolerFetchCourseDataLoader(false));
            // yield put(errorHandler(error));
            yield put(errorHandler({error: error, page: ''}));
          }
        }

        // if resource type is content html
        else if (resource_type === CONTENT_HTML) {
          // socket connections
          yield put(akFetchVrResourceCompleteData());
          yield put(amerikoolerSaveContentHtmlData({...currentResource}));
        }
      }
    }

    //fetch current video played seconds from server
    if (!!sessionStorage.getItem('Authorization')) {
      const videoTrackingDetails = yield axios.get(`/user-seek-video-time`);
      if (videoTrackingDetails.status === 200 && !isEmpty(videoTrackingDetails.data)) {
        yield put(akSavehVideoPlayedSeconds(videoTrackingDetails.data));
      }
    }

    yield put(amerikoolerFetchCourseDataLoader(false));
  } catch (error) {
    yield put(amerikoolerFetchCourseDataLoader(false));
    // yield put(errorHandler(error));
    yield put(errorHandler({error: error, page: ''}));
  }
}
// amerikooler course fetch data watcher
export function* akFetchCoursesDataWatcher() {
  yield takeLatest(coursesType.AK_FETCH_COURSE_DATA, akFetchCoursesDataWorker);
}

// amerikooler next step worker
export function* akNextStepWorker() {
  yield put(amerikoolerNextStepLoader(true));
  const reduxState = yield select();
  const {amerikoolerCourse} = reduxState;
  const {akCurrentUnit, akCurrentLesson, akCurrentResource, akHmiSteps} = amerikoolerCourse;
  const {lessons} = !!akCurrentUnit && akCurrentUnit;
  const {resources, lesson_id} = !!akCurrentLesson && akCurrentLesson;
  const {authToken} = !!akHmiSteps && akHmiSteps;
  try {
    const activeResourseKey = findKey(resources, ['resource_id', !!akCurrentResource && akCurrentResource.resource_id]);
    if (isUndefined(activeResourseKey)) {
      return;
    }
    // check resource is completed or not completed
    const checkResourceCompleteOrNot = yield axios.get(`/resource/check-complete/${akCurrentResource.resource_id}`);

    const {is_complete} = checkResourceCompleteOrNot.data;
    if (checkResourceCompleteOrNot.status === 200 && is_complete) {
      yield put(amerikoolerCourseLoader(true));
      const nextResource = nextPreviousArrayValue(resources, parseInt(activeResourseKey), NEXT);
      const {step} = !!nextResource && nextResource;
      // if next resourse is available then render next resourse
      if (!!step && !!step.resource_id) {
        const {resource_type, resource_id, content_status} = !!step && step;

        /* if resource is publish */
        if (content_status === PUBLISH) {
          //if resource type is scorm
          if (resource_type === 'Web Resource') {
            const resourceDetailsResponse = yield axios.get(`/resource/${resource_id}`);
            const resourceDetails = resourceDetailsResponse.data[0];
            if (resourceDetailsResponse.status === 200 && !isEmpty(resourceDetails)) {
              const {steps} = !!resourceDetails && resourceDetails;
              const scormData = {
                ...steps[0],
                resourceDetails,
              };
              yield put(amerikoolerUpdateCurrentUnit({resource_id: akCurrentResource.resource_id, lesson_id}));
              yield put(amerikoolerSaveScormData({...scormData}));
              yield put(amerikoolerSaveCurrentResource({...step}));

              // socket connections
              yield put(akFetchVrResourceCompleteData());
            }
          }

          // if resource type is pdf
          else if (resource_type === FILE) {
            const resourceDetailsResponse = yield axios.get(`/resource/${resource_id}`);
            const resourceDetails = resourceDetailsResponse.data[0];
            if (resourceDetailsResponse.status === 200 && !isEmpty(resourceDetails)) {
              const {steps, content_type_id} = !!resourceDetails && resourceDetails;
              const resData = {
                ...steps[0],
              };
              yield put(amerikoolerUpdateCurrentUnit({resource_id: akCurrentResource.resource_id, lesson_id}));
              /* if resource type is video */
              if (content_type_id === 'FILE') {
                yield put(amerikoolerSaveVideoData({...resData}));
                yield put(amerikoolerSavePdfData(null));
              }

              //if resource type is pdf
              else if (content_type_id === 'PDF') {
                yield put(amerikoolerSavePdfData({...resData}));
                yield put(amerikoolerSaveVideoData(null));
              }
              yield put(amerikoolerSaveCurrentResource({...step}));
              // socket connections
              yield put(akFetchVrResourceCompleteData());
            }
          }

          // if resource type is vr
          else if (resource_type === VR) {
            const resourceDetailsResponse = yield axios.get(`/resource/${resource_id}`);
            const resourceDetails = resourceDetailsResponse.data[0];

            if (resourceDetailsResponse.status === 200 && !isEmpty(resourceDetails)) {
              const {steps, steps_type} = !!resourceDetails && resourceDetails;
              let getGemetriViewUrl = null;
              // when vr resource has only one child steps
              if (steps_type === VR_STEPS_TYPE_CONTENT) {
                // for gemetri view url api
                getGemetriViewUrl = yield axios.get(`/gmetri/view/${resource_id}`);
              }
              // when vr resource has multiple child resources
              else if (steps_type === VR_STEPS_TYPE_RESOURSE) {
                const {id} = steps[0];
                // for gemetri view url api
                getGemetriViewUrl = yield axios.get(`/gmetri/view/${id}`);
              }
              const vrDatas = {...resourceDetails, url: !!getGemetriViewUrl && getGemetriViewUrl.data.auth_url ? getGemetriViewUrl.data.auth_url : ''};
              yield put(amerikoolerUpdateCurrentUnit({resource_id: akCurrentResource.resource_id, lesson_id}));
              yield put(amerikoolerSaveVrData({...vrDatas}));
              yield put(amerikoolerSaveCurrentResource({...step}));
              // socket connections
              yield put(akFetchVrResourceCompleteData());
            }
          }

          // if resource type is hmi
          else if (resource_type === HMI) {
            const resourceDetailsResponse = yield axios.get(`/resource/${resource_id}`);
            const resourceDetails = resourceDetailsResponse.data[0];

            if (resourceDetailsResponse.status === 200 && !isEmpty(resourceDetails)) {
              // Jsat EndSession
              if (!isEmpty(authToken)) {
                // yield axios.post(`/jsat/end-session`, {jsat_jwt_token: authToken});
              }
              const hmiResponceData = yield axios.get(`/jsat/init/${resource_id}`);
              /* yield put(fetchProgressData());*/

              if (hmiResponceData.status === 200) {
                const {steps} = !!resourceDetails && resourceDetails;
                const hmiData = {
                  ...steps[0],
                };
                yield put(amerikoolerUpdateCurrentUnit({resource_id: akCurrentResource.resource_id, lesson_id}));
                yield put(amerikoolerSaveHmiData({...hmiData}));
                yield put(amerikoolerSaveHmiSteps({hmiSteps: hmiResponceData.data}));
                yield put(amerikoolerSaveCurrentResource({...step}));
                // socket connections
                yield put(akFetchVrResourceCompleteData());
              }
            }
          }

          // if resource type is content html
          else if (resource_type === CONTENT_HTML) {
            yield put(amerikoolerUpdateCurrentUnit({resource_id: akCurrentResource.resource_id, lesson_id}));
            yield put(amerikoolerSaveContentHtmlData({...step}));
            yield put(amerikoolerSaveCurrentResource({...step}));
            // socket connections
            yield put(akFetchVrResourceCompleteData());
          }
        }

        // if resource type is pending
        else if (content_status === PENDING) {
          yield put(amerikoolerSaveCurrentResource({...step}));
        }
      }
      // if next resourse is not available then render next lesson resource
      else {
        const activeLessonKey = findKey(lessons, ['lesson_id', lesson_id]);
        const nextLesson = nextPreviousArrayValue(lessons, parseInt(activeLessonKey), NEXT);
        const {step} = nextLesson;
        // update lesson complete
        yield put(amerikoolerUpdateCurrentUnit({resource_id: akCurrentResource.resource_id, lesson_id, type: 'lesson_complete'}));

        // if next lesson is available then render next lesson
        if (!!step && !!step.lesson_id) {
          const {resources} = step;
          const firstResource = !!resources && resources[0];
          if (!isEmpty(firstResource)) {
            const {resource_type, resource_id, content_status} = firstResource;
            /* if resource is publish */
            if (content_status === PUBLISH) {
              // if resource type is scorm
              if (resource_type === 'Web Resource') {
                const resourceDetailsResponse = yield axios.get(`/resource/${resource_id}`);
                const resourceDetails = resourceDetailsResponse.data[0];
                if (resourceDetailsResponse.status === 200 && !isEmpty(resourceDetails)) {
                  const {steps} = !!resourceDetails && resourceDetails;
                  const scormData = {
                    ...steps[0],
                    resourceDetails,
                  };
                  yield put(amerikoolerSaveScormData({...scormData}));
                  yield put(amerikoolerSaveCurrentResource({...firstResource}));
                  yield put(amerikoolerSaveCurrentLesson({...step}));
                  // socket connections
                  yield put(akFetchVrResourceCompleteData());
                }
              }

              // if resource type is File/Pdf
              else if (resource_type === FILE) {
                const resourceDetailsResponse = yield axios.get(`/resource/${resource_id}`);
                const resourceDetails = resourceDetailsResponse.data[0];
                if (resourceDetailsResponse.status === 200 && !isEmpty(resourceDetails)) {
                  const {steps, content_type_id} = !!resourceDetails && resourceDetails;
                  const resData = {
                    ...steps[0],
                  };

                  /* if resource type is video */
                  if (content_type_id === 'FILE') {
                    yield put(amerikoolerSaveVideoData({...resData}));
                    yield put(amerikoolerSavePdfData(null));
                  }

                  //if resource type is pdf
                  else if (content_type_id === 'PDF') {
                    yield put(amerikoolerSavePdfData({...resData}));
                    yield put(amerikoolerSaveVideoData(null));
                  }
                  yield put(amerikoolerSaveCurrentResource({...firstResource}));
                  yield put(amerikoolerSaveCurrentLesson({...step}));
                  // socket connections
                  yield put(akFetchVrResourceCompleteData());
                }
              }

              // if resource type is VR
              else if (resource_type === VR) {
                const resourceDetailsResponse = yield axios.get(`/resource/${resource_id}`);
                const resourceDetails = resourceDetailsResponse.data[0];

                if (resourceDetailsResponse.status === 200 && !isEmpty(resourceDetails)) {
                  const {steps, steps_type} = !!resourceDetails && resourceDetails;
                  let getGemetriViewUrl = null;
                  // when vr resource has only one child steps
                  if (steps_type === VR_STEPS_TYPE_CONTENT) {
                    // for gemetri view url api
                    getGemetriViewUrl = yield axios.get(`/gmetri/view/${resource_id}`);
                  }
                  // when vr resource has multiple child resources
                  else if (steps_type === VR_STEPS_TYPE_RESOURSE) {
                    const {id} = steps[0];
                    // for gemetri view url api
                    getGemetriViewUrl = yield axios.get(`/gmetri/view/${id}`);
                  }
                  const vrDatas = {...steps[0], url: !!getGemetriViewUrl && getGemetriViewUrl.data.auth_url ? getGemetriViewUrl.data.auth_url : ''};
                  yield put(amerikoolerSaveVrData({...vrDatas}));
                  yield put(amerikoolerSaveCurrentResource({...firstResource}));
                  yield put(amerikoolerSaveCurrentLesson({...step}));
                  // socket connections
                  yield put(akFetchVrResourceCompleteData());
                }
              }

              // if resource type is HMI
              else if (resource_type === HMI) {
                const resourceDetailsResponse = yield axios.get(`/resource/${resource_id}`);
                const resourceDetails = resourceDetailsResponse.data[0];

                if (resourceDetailsResponse.status === 200 && !isEmpty(resourceDetails)) {
                  // Jsat EndSession
                  if (!isEmpty(authToken)) {
                    // yield axios.post(`/jsat/end-session`, {jsat_jwt_token: authToken});
                  }
                  const hmiResponceData = yield axios.get(`/jsat/init/${resource_id}`);
                  /* yield put(fetchProgressData());*/

                  if (hmiResponceData.status === 200) {
                    const {steps} = !!resourceDetails && resourceDetails;
                    const hmiData = {
                      ...steps[0],
                    };
                    yield put(amerikoolerSaveHmiData({...hmiData}));
                    yield put(amerikoolerSaveHmiSteps({hmiSteps: hmiResponceData.data}));
                    yield put(amerikoolerSaveCurrentResource({...firstResource}));
                    yield put(amerikoolerSaveCurrentLesson({...step}));
                    // socket connections
                    yield put(akFetchVrResourceCompleteData());
                  }
                }
              }

              // if resource type is content html
              else if (resource_type === CONTENT_HTML) {
                yield put(amerikoolerSaveContentHtmlData({...firstResource}));
                yield put(amerikoolerSaveCurrentResource({...firstResource}));
                yield put(amerikoolerSaveCurrentLesson({...step}));
                // socket connections
                yield put(akFetchVrResourceCompleteData());
              }
            }

            // if resource type is pending
            else if (content_status === PENDING) {
              yield put(amerikoolerSaveCurrentResource({...firstResource}));
              yield put(amerikoolerSaveCurrentLesson({...step}));
            }
          }
        }
      }
    }

    // if resource is not completed
    else {
      yield ToastManager.error('Please Complete Current Resource', null, 3000);
    }
    yield put(amerikoolerNextStepLoader(false));
    yield put(amerikoolerCourseLoader(false));
  } catch (error) {
    yield put(amerikoolerNextStepLoader(false));
    yield put(amerikoolerCourseLoader(false));
    // yield put(errorHandler(error));
    yield put(errorHandler({error: error, page: ''}));
  }
}
// amerikooler next step watcher
export function* akNextStepWatcher() {
  yield takeLatest(coursesType.AK_NEXT_STEP, akNextStepWorker);
}

// amerikooler previous step worker
export function* akPreviousStepWorker() {
  try {
    yield put(amerikoolerPreviousStepLoader(true));
    const reduxState = yield select();
    const {amerikoolerCourse} = reduxState;
    const {akCurrentUnit, akCurrentLesson, akCurrentResource, akHmiSteps} = amerikoolerCourse;
    const {lessons} = !!akCurrentUnit && akCurrentUnit;
    const {resources, lesson_id} = !!akCurrentLesson && akCurrentLesson;
    const {authToken} = !!akHmiSteps && akHmiSteps;
    const activeResourseKey = findKey(resources, ['resource_id', !!akCurrentResource && akCurrentResource.resource_id]);
    if (isUndefined(activeResourseKey)) {
      return;
    }
    // check resource is completed or not completed
    const checkResourceCompleteOrNot = yield axios.get(`/resource/check-complete/${akCurrentResource.resource_id}`);
    const {is_complete} = checkResourceCompleteOrNot.data;
    if (checkResourceCompleteOrNot.status === 200 && is_complete) {
      yield put(amerikoolerCourseLoader(true));
      const previousResourse = nextPreviousArrayValue(resources, parseInt(activeResourseKey), PREVIOUS);
      const {step} = previousResourse;
      //if previous resource available of active lesson
      if (!!step && !!step.resource_id) {
        const {resource_type, resource_id, content_status} = !!step && step;
        // if resource is publish
        if (content_status === PUBLISH) {
          // if resource type is scorm
          if (resource_type === 'Web Resource') {
            const resourceDetailsResponse = yield axios.get(`/resource/${resource_id}`);
            const resourceDetails = resourceDetailsResponse.data[0];
            if (resourceDetailsResponse.status === 200 && !isEmpty(resourceDetails)) {
              const {steps} = !!resourceDetails && resourceDetails;
              const scormData = {
                ...steps[0],
                resourceDetails,
              };
              yield put(amerikoolerUpdateCurrentUnit({resource_id: akCurrentResource.resource_id, lesson_id}));
              yield put(amerikoolerSaveScormData({...scormData}));
              yield put(amerikoolerSaveCurrentResource({...step}));
              // socket connections
              yield put(akFetchVrResourceCompleteData());
            }
          }

          // if resource type is File/Pdf
          else if (resource_type === FILE) {
            const resourceDetailsResponse = yield axios.get(`/resource/${resource_id}`);
            const resourceDetails = resourceDetailsResponse.data[0];
            if (resourceDetailsResponse.status === 200 && !isEmpty(resourceDetails)) {
              const {steps, content_type_id} = !!resourceDetails && resourceDetails;
              const resData = {
                ...steps[0],
              };
              yield put(amerikoolerUpdateCurrentUnit({resource_id: akCurrentResource.resource_id, lesson_id}));

              /* if resource type is video */
              if (content_type_id === 'FILE') {
                yield put(amerikoolerSaveVideoData({...resData}));
                yield put(amerikoolerSavePdfData(null));
              }

              //if resource type is pdf
              else if (content_type_id === 'PDF') {
                yield put(amerikoolerSavePdfData({...resData}));
                yield put(amerikoolerSaveVideoData(null));
              }
              yield put(amerikoolerSaveCurrentResource({...step}));
              // socket connections
              yield put(akFetchVrResourceCompleteData());
            }
          }

          // if resource type is VR
          else if (resource_type === VR) {
            const resourceDetailsResponse = yield axios.get(`/resource/${resource_id}`);
            const resourceDetails = resourceDetailsResponse.data[0];

            if (resourceDetailsResponse.status === 200 && !isEmpty(resourceDetails)) {
              const {steps, steps_type} = !!resourceDetails && resourceDetails;

              let getGemetriViewUrl = null;
              // when vr resource has only one child steps
              if (steps_type === VR_STEPS_TYPE_CONTENT) {
                // for gemetri view url api
                getGemetriViewUrl = yield axios.get(`/gmetri/view/${resource_id}`);
              }
              // when vr resource has multiple child resources
              else if (steps_type === VR_STEPS_TYPE_RESOURSE) {
                const {id} = steps[0];
                // for gemetri view url api
                getGemetriViewUrl = yield axios.get(`/gmetri/view/${id}`);
              }
              const vrDatas = {...steps[0], url: !!getGemetriViewUrl && getGemetriViewUrl.data.auth_url ? getGemetriViewUrl.data.auth_url : ''};
              yield put(amerikoolerUpdateCurrentUnit({resource_id: akCurrentResource.resource_id, lesson_id}));
              yield put(amerikoolerSaveVrData({...vrDatas}));
              yield put(amerikoolerSaveCurrentResource({...step}));
              // socket connections
              yield put(akFetchVrResourceCompleteData());
            }
          }

          // if resource type is hmi
          else if (resource_type === HMI) {
            const resourceDetailsResponse = yield axios.get(`/resource/${resource_id}`);
            const resourceDetails = resourceDetailsResponse.data[0];

            if (resourceDetailsResponse.status === 200 && !isEmpty(resourceDetails)) {
              // Jsat EndSession
              if (!isEmpty(authToken)) {
                // yield axios.post(`/jsat/end-session`, {jsat_jwt_token: authToken});
              }
              const hmiResponceData = yield axios.get(`/jsat/init/${resource_id}`);
              /* yield put(fetchProgressData());*/

              if (hmiResponceData.status === 200) {
                const {steps} = !!resourceDetails && resourceDetails;
                const hmiData = {
                  ...steps[0],
                };
                yield put(amerikoolerUpdateCurrentUnit({resource_id: akCurrentResource.resource_id, lesson_id}));
                yield put(amerikoolerSaveHmiData({...hmiData}));
                yield put(amerikoolerSaveHmiSteps({hmiSteps: hmiResponceData.data}));
                yield put(amerikoolerSaveCurrentResource({...step}));
                // socket connections
                yield put(akFetchVrResourceCompleteData());
              }
            }
          }

          // if resource type is content html
          else if (resource_type === CONTENT_HTML) {
            yield put(amerikoolerUpdateCurrentUnit({resource_id: akCurrentResource.resource_id, lesson_id}));
            yield put(amerikoolerSaveContentHtmlData({...step}));
            yield put(amerikoolerSaveCurrentResource({...step}));
            // socket connections
            yield put(akFetchVrResourceCompleteData());
          }
        }
        // if resource is pending
        if (content_status === PENDING) {
          yield put(amerikoolerSaveCurrentResource({...step}));
        }
      }
      //if previous resource not available of active lesson then render previous lesson
      else {
        const activeLessonKey = findKey(lessons, ['lesson_id', lesson_id]);
        const previousLesson = nextPreviousArrayValue(lessons, parseInt(activeLessonKey), PREVIOUS);
        const {step} = previousLesson;
        // update lesson complete
        yield put(amerikoolerUpdateCurrentUnit({resource_id: akCurrentResource.resource_id, lesson_id, type: 'lesson_complete'}));
        // if previous lesson is available then render previous lesson
        if (!!step && !!step.lesson_id) {
          const {resources} = step;
          const lastResource = !!resources && resources[resources.length - 1];
          if (!isEmpty(lastResource)) {
            const {resource_type, resource_id, content_status} = lastResource;
            // if resource is publish
            if (content_status === PUBLISH) {
              // if resource type is scorm
              if (resource_type === 'Web Resource') {
                const resourceDetailsResponse = yield axios.get(`/resource/${resource_id}`);
                const resourceDetails = resourceDetailsResponse.data[0];
                if (resourceDetailsResponse.status === 200 && !isEmpty(resourceDetails)) {
                  const {steps} = !!resourceDetails && resourceDetails;
                  const scormData = {
                    ...steps[0],
                    resourceDetails,
                  };
                  yield put(amerikoolerSaveScormData({...scormData}));
                  yield put(amerikoolerSaveCurrentResource({...lastResource}));
                  yield put(amerikoolerSaveCurrentLesson({...step}));
                  // socket connections
                  yield put(akFetchVrResourceCompleteData());
                }
              }

              // if resource type is File/PDf
              else if (resource_type === FILE) {
                const resourceDetailsResponse = yield axios.get(`/resource/${resource_id}`);
                const resourceDetails = resourceDetailsResponse.data[0];
                if (resourceDetailsResponse.status === 200 && !isEmpty(resourceDetails)) {
                  const {steps, content_type_id} = !!resourceDetails && resourceDetails;
                  const resData = {
                    ...steps[0],
                  };
                  /* if resource type is video */
                  if (content_type_id === 'FILE') {
                    yield put(amerikoolerSaveVideoData({...resData}));
                    yield put(amerikoolerSavePdfData(null));
                  }

                  //if resource type is pdf
                  else if (content_type_id === 'PDF') {
                    yield put(amerikoolerSavePdfData({...resData}));
                    yield put(amerikoolerSaveVideoData(null));
                  }
                  yield put(amerikoolerSaveCurrentResource({...lastResource}));
                  yield put(amerikoolerSaveCurrentLesson({...step}));
                  // socket connections
                  yield put(akFetchVrResourceCompleteData());
                }
              }

              // if resource type is VR
              else if (resource_type === VR) {
                const resourceDetailsResponse = yield axios.get(`/resource/${resource_id}`);
                const resourceDetails = resourceDetailsResponse.data[0];

                if (resourceDetailsResponse.status === 200 && !isEmpty(resourceDetails)) {
                  const {steps, steps_type} = !!resourceDetails && resourceDetails;

                  let getGemetriViewUrl = null;
                  // when vr resource has only one child steps
                  if (steps_type === VR_STEPS_TYPE_CONTENT) {
                    // for gemetri view url api
                    getGemetriViewUrl = yield axios.get(`/gmetri/view/${resource_id}`);
                  }
                  // when vr resource has multiple child resources
                  else if (steps_type === VR_STEPS_TYPE_RESOURSE) {
                    const {id} = steps[0];
                    // for gemetri view url api
                    getGemetriViewUrl = yield axios.get(`/gmetri/view/${id}`);
                  }
                  const vrDatas = {...steps[0], url: !!getGemetriViewUrl && getGemetriViewUrl.data.auth_url ? getGemetriViewUrl.data.auth_url : ''};
                  yield put(amerikoolerSaveVrData({...vrDatas}));
                  yield put(amerikoolerSaveCurrentResource({...lastResource}));
                  yield put(amerikoolerSaveCurrentLesson({...step}));
                  // socket connections
                  yield put(akFetchVrResourceCompleteData());
                }
              }
              // if resource type is hmi
              else if (resource_type === HMI) {
                const resourceDetailsResponse = yield axios.get(`/resource/${resource_id}`);
                const resourceDetails = resourceDetailsResponse.data[0];

                if (resourceDetailsResponse.status === 200 && !isEmpty(resourceDetails)) {
                  // Jsat EndSession
                  if (!isEmpty(authToken)) {
                    // yield axios.post(`/jsat/end-session`, {jsat_jwt_token: authToken});
                  }
                  const hmiResponceData = yield axios.get(`/jsat/init/${resource_id}`);
                  /* yield put(fetchProgressData());*/

                  if (hmiResponceData.status === 200) {
                    const {steps} = !!resourceDetails && resourceDetails;
                    const hmiData = {
                      ...steps[0],
                    };
                    yield put(amerikoolerSaveHmiData({...hmiData}));
                    yield put(amerikoolerSaveHmiSteps({hmiSteps: hmiResponceData.data}));
                    yield put(amerikoolerSaveCurrentResource({...lastResource}));
                    yield put(amerikoolerSaveCurrentLesson({...step}));
                    // socket connections
                    yield put(akFetchVrResourceCompleteData());
                  }
                }
              }
              // if resource type is content html
              else if (resource_type === CONTENT_HTML) {
                yield put(amerikoolerSaveContentHtmlData({...lastResource}));
                yield put(amerikoolerSaveCurrentResource({...lastResource}));
                yield put(amerikoolerSaveCurrentLesson({...step}));
                // socket connections
                yield put(akFetchVrResourceCompleteData());
              }
            }
            // if resource is pending
            else if (content_status === PENDING) {
              yield put(amerikoolerSaveCurrentResource({...lastResource}));
              yield put(amerikoolerSaveCurrentLesson({...step}));
            }
          }
        }
      }
    }
    // if resource is not completed
    else {
      yield ToastManager.error('Please Complete Current Resource', null, 3000);
    }
    yield put(amerikoolerPreviousStepLoader(false));
    yield put(amerikoolerCourseLoader(false));
  } catch (error) {
    yield put(amerikoolerPreviousStepLoader(false));
    yield put(amerikoolerCourseLoader(false));
    // yield put(errorHandler(error));
    yield put(errorHandler({error: error, page: ''}));
  }
}
// amerikooler previous step watcher
export function* akPreviousStepWatcher() {
  yield takeLatest(coursesType.AK_PREVIOUS_STEP, akPreviousStepWorker);
}

// hmi simulator end session
function* akHmiSessionEndWorker() {
  try {
    const reduxState = yield select();
    const {amerikoolerCourse} = reduxState;
    const {akHmiSteps} = !!amerikoolerCourse && amerikoolerCourse;
    const {authToken} = !!akHmiSteps && akHmiSteps;
    if (!isEmpty(authToken)) {
      yield axios.post(`/jsat/end-session`, {jsat_jwt_token: authToken});
    }
  } catch (error) {
    if (!error.response) {
      // yield put(errorHandler(error));
      yield put(errorHandler({error: error, page: ''}));
    }
  }
}
export function* akHmiSessionEndWatcher() {
  yield takeLatest(coursesType.AK_HMI_SESSION_END, akHmiSessionEndWorker);
}

// update server how much time user spend on course
export function* updateUserInfo({payload}) {
  try {
    const sentFormData = {...payload};
    const {resource_id} = sentFormData;
    const reduxState = yield select();
    const {amerikoolerCourse} = reduxState;
    const {akLockCourseBtn} = amerikoolerCourse;
    // const {resource_type} = akCurrentResource;
    // if (resource_type !== VR && resource_type !== 'Web Resource') {
    yield axios.post(`/tracking/progress`, sentFormData);
    if (akLockCourseBtn) {
      // check resource is completed or not completed
      const checkResourceCompleteOrNot = yield axios.get(`/resource/check-complete/${resource_id}`);
      const {is_complete} = checkResourceCompleteOrNot.data;
      if (checkResourceCompleteOrNot.status === 200 && is_complete) {
        yield put(amerikoolerLockCourseBtnFunc(false));
      }
    }
    // }
  } catch (error) {
    // yield put(errorHandler(error));
    yield put(errorHandler({error: error, page: ''}));
  }
}

// course details fetch watcher
export function* updateUserInfoWatcher() {
  yield takeEvery(coursesType.UPDATE_USER_INFO_TIME_INTERVAL, updateUserInfo);
}

// update user time interval when pdf is mounted
export function* updateUserInfoTimeWorker({payload}) {
  try {
    const sentFormData = {...payload};
    const {resource_id} = sentFormData;
    const reduxState = yield select();
    const {amerikoolerCourse} = reduxState;
    const {akLockCourseBtn} = amerikoolerCourse;
    // const {resource_type} = akCurrentResource;
    // if (resource_type !== VR) {
    const {auth} = reduxState;

    if (auth?.authUser != null) {
      yield axios.post(`/tracking/progress`, sentFormData);

      if (akLockCourseBtn) {
        // check resource is completed or not completed
        const checkResourceCompleteOrNot = yield axios.get(`/resource/check-complete/${resource_id}`);
        const {is_complete} = checkResourceCompleteOrNot.data;
        if (checkResourceCompleteOrNot.status === 200 && is_complete) {
          yield put(amerikoolerLockCourseBtnFunc(false));
        }
      }
    }
  } catch (error) {
    // yield put(errorHandler(error));
    yield put(updateUserInfoTimeLoader(false));
    yield put(errorHandler({error: error, page: ''}));
  }
}

// course details fetch watcher
export function* updateUserInfoTimeWatcher() {
  yield takeEvery(coursesType.UPDATE_USER_INFO_TIME, updateUserInfoTimeWorker);
}

/* socket code start */

// Socket connections
function connect() {
  const socket = laravelEchoOptions().client(laravelEchoOptions().host);
  arr = [];
  return new Promise(resolve => {
    socket.on('connect', () => {
      console.log('Socket CONNECTED');
      resolve(socket);
    });
  });
}

function* read(socket) {
  const reduxState = yield select();
  const {auth, amerikoolerCourse} = reduxState;
  const {userId} = auth;
  const {akCurrentResource, akLockCourseBtn, akFirstResourceId, akLastResourceId, singleScormCertificateButtonEnable} =
    !!amerikoolerCourse && amerikoolerCourse;
  const {resource_id, resource_type} = !!akCurrentResource && akCurrentResource;
  // if user come from login send user id from auth and if user comes from isoTrainLink send extUserId
  const channel = yield call(subscribe, {
    socket,
    userId: userId,
    currentResourceId: resource_id,
    akLockCourseBtn,
    resource_type,
    akFirstResourceId,
    akLastResourceId,
    singleScormCertificateButtonEnable,
  });
  yield fork(function*() {
    yield take('CANCEL_WATCH');
    channel.close();
  });

  try {
    while (true) {
      let action = yield take(channel);
      yield put(action);
    }
  } catch (error) {
    yield put(channel.cancelWatch()); // to emit 'CANCEL_WATCH'
  }
}

export function subscribe(payload) {
  const {socket, userId, currentResourceId, akLockCourseBtn, resource_type, akFirstResourceId, akLastResourceId, singleScormCertificateButtonEnable} = payload;
  const echo = new Echo({...laravelEchoOptions()});

  // let channel = echo.private(`action.${userId}`)
  return new eventChannel(emit => {
    /* echo.private(`action.${userId}`).listen('Action', event => {
      const {message} = event;
      const {action} = !!message && message;
      if (action === 'completed' && akLockCourseBtn) {
        emit(amerikoolerLockCourseBtnFunc(false));
      }
    }); */
    echo.private(`resourcecompleted.${userId}`).listen('Resourcecompleted', event => {
      const {message} = !!event && event;
      const {resource_id} = !!message && message;
      if (resource_id === currentResourceId && akLockCourseBtn && !includes(arr, resource_id)) {
        arr.push(resource_id);
        emit(amerikoolerLockCourseBtnFunc(false));
      }
    });

    echo.private(`score.${userId}`).listen('Score', event => {
      const {message} = !!event && event;
      const {score, lesson_status} = !!message && message;
      // if course is single resource
      if (akFirstResourceId === akLastResourceId && resource_type === 'Web Resource' && !isEmpty(score)) {
        const {raw} = !!score && score;
        if (!isEmpty(raw)) {
          emit(akSaveSingleScormResourcePorgress(parseFloat(raw)));
        }
        if (singleScormCertificateButtonEnable && !!lesson_status && (lesson_status === 'passed' || lesson_status === 'completed')) {
          emit(akSingleScormResourceCertificateButtonEnable(false));
        }
      }
    });
    // echo.private(`message.${userId}`).listen('Message', event => {
    // const {message} = event;

    // if (message.message === FAILED) {
    // emit(showHideInstructionsAlertBox(true));
    // }
    // });
    return () => {
      socket.close().then(() => {
        console.log('socket closed');
      });
      // This is a handler to unsubscribe.
    };
  });
}

// fetch progress data data
export function* akFetchVrResourceCompleteDataWorker() {
  const socket = yield call(connect);
  yield fork(read, socket);
}

//amerikooler fetch resource completed data watcher
export function* akFetchVrResourceCompleteDataWatcher() {
  yield takeEvery(coursesType.AK_FETCH_VR_RESOURCE_COMPLETE_DATA, akFetchVrResourceCompleteDataWorker);
}

//update current video played seconds to server worker
export function* akUpdateVideoPlayedSecondsWorker({payload}) {
  try {
    const sentFormData = {...payload};
    const response = yield axios.post(`/tracking/video-seek-time`, sentFormData);
    if (response.status === 200 && !isEmpty(response.data)) {
      yield put(akSavehUpdatedVideoPlayedSeconds({...sentFormData}));
    }
  } catch (error) {
    // yield put(errorHandler(error));
    yield put(errorHandler({error: error, page: ''}));
  }
}

//update current video played seconds to server watcher
export function* akUpdateVideoPlayedSecondsWatcher() {
  yield takeEvery(coursesType.AK_UPDATE_VIDEO_PLAYED_SECONDS, akUpdateVideoPlayedSecondsWorker);
}

//fetch current video played seconds from server worker
export function* akFetchVideoPlayedSecondsWorker() {
  try {
    const videoTrackingDetails = yield axios.get(`/user-seek-video-time`);
    if (videoTrackingDetails.status === 200 && !isEmpty(videoTrackingDetails.data)) {
      yield put(akSavehVideoPlayedSeconds(videoTrackingDetails.data));
    }
  } catch (error) {
    // yield put(errorHandler(error));
    yield put(errorHandler({error: error, page: ''}));
  }
}

//fetch current video played seconds from server watcher
export function* akFetchVideoPlayedSecondsWatcher() {
  yield takeEvery(coursesType.AK_FETCH_VIDEO_PLAYED_SECONDS, akFetchVideoPlayedSecondsWorker);
}

/* socket code end */

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([
    fork(akFetchCertificateWatcher),
    fork(akFetchCoursesDataWatcher),
    fork(tdnFetchCoursesDataWatcher),
    fork(tdnFetchResourceDataWatcher),
    fork(tdnLaunchDataWatcher),
    fork(updateUserInfoWatcher),
    fork(akNextStepWatcher),
    fork(akPreviousStepWatcher),
    fork(akHmiSessionEndWatcher),
    fork(updateUserInfoTimeWatcher),
    fork(akFetchVrResourceCompleteDataWatcher),
    fork(akUpdateVideoPlayedSecondsWatcher),
    fork(akFetchVideoPlayedSecondsWatcher),
  ]);
}
