import {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {userSignOut, flushAppDetails, setInitUrl} from '../redux/actions/Auth';
import {fetchPconfig} from '../redux/actions/Auth';
import {getDefaultDomainDetails} from 'util/Extra';
import {QUVA} from 'constants/Constants';

class LogOut extends Component {
  componentWillMount() {
    const {clientConfigration, history} = this.props;
    const {serverConfig} = clientConfigration;
    const {institute_shoppingcart_logout_url} = !!serverConfig && serverConfig;
    var redirectURL = null;
    if (!!institute_shoppingcart_logout_url) {
      sessionStorage.removeItem('component_route');
      sessionStorage.removeItem('currentClientFromUrl');
      redirectURL = institute_shoppingcart_logout_url;
    }
    //if (!!redirectURL) window.location.href = redirectURL;
    if (!!redirectURL) {
      const pathArray = redirectURL.split('/');
      const protocol = pathArray[0];
      const host = pathArray[2];
      const url = protocol + '//' + host;
      const localurl = 'http://localhost:3000';
      if (window.location.origin !== url && window.location.origin !== localurl) {
        if (redirectURL.includes('cart') === true || getDefaultDomainDetails().instituteName === QUVA) {
          window.location.href = redirectURL;
        } else {
          history.push('/signin');
        }
        //window.location.href = redirectURL
        //history.push('/signin')
        //  console.log('logout componentWillMount called inside redirect url')
        //  console.log(JSON.stringify(serverConfig))
      } else {
        history.push('/signin');
        // console.log('else part logout componentWillMount called inside redirect url')
      }
    } else this.props.fetchPconfig();
    //setTimeout(() => { }, 500)
  }

  render() {
    return null;
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      userSignOut,
      flushAppDetails,
      setInitUrl,
      fetchPconfig,
    },
    dispatch,
  );
};

const mapStateToProps = ({auth}) => {
  const {clientConfigration, userId, session_id} = auth;
  return {clientConfigration, userId, session_id};
};

export default connect(mapStateToProps, mapDispatchToProps)(LogOut);
