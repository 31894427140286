/* istanbul ignore file */

//Change Password Component
import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {set_password_regex, getDefaultDomainDetails, dynamicThemeAllowMenus} from '../../util/Extra';
import {connect} from 'react-redux';
import {forgotPasswordStart, setUpdatePasswordToken, changePasswordFromEmailLink} from '../../redux/actions/Auth';
import {bindActionCreators} from 'redux';
import {withStyles} from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import SubmitLoader from 'components/SubmitLoader/SubmitLoader';
import getQueryParams from 'util/QueryString';
import {globalMessages} from 'util/Extra';
import {setInitUrl} from 'redux/actions/Auth';
import {setThemeColor} from '../../redux/actions/Setting';
import {IconButton, InputAdornment} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import "../../app/routes/Instructor/routes/ChangePassword/ChangePassword.scss";

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(15),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
    borderRadius: ' 50px',
  },
  input: {
    background: '#f4f7fc',
    '&:-webkit-autofill': {
      background: '#f4f7fc',
    },
  },
  btnsubmit: {
    width: '100%',
    fontFamily: 'ITC Avant Garde Std Md',
    padding: '10px 25px !important',
    borderRadius: ' 50px',
    fontSize: '16px',
    marginBottom: '15px',
    background: '#525CA3',
    '&:hover ': {
      background: '#525CA3',
    },
  },
  error: {
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E05757',
    },
  },
});

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordDetails: {
        newPassword: '',
        confirmPassword: '',
      },
      inputError: {
        newPasswordError: '',
        confirmPasswordError: '',
      },
      showNewPassword: false,
      showConfirmPassword: false
    };
  }

  //Validation for email
  validate = () => {
    const {newPassword, confirmPassword} = this.state.passwordDetails;
    let inputError = this.state.inputError;
    inputError = {
      newPasswordError: '',
      confirmPasswordError: '',
    };
    if (!newPassword.length > 0) {
      inputError.newPasswordError = 'Required';
    }
    if (newPassword.length > 0 && !set_password_regex.test(newPassword)) {
      inputError.newPasswordError = globalMessages.passwordValidationErrorMessage;
    }
    if (!confirmPassword.length > 0) {
      inputError.confirmPasswordError = 'Required';
    }
    if (newPassword !== confirmPassword && confirmPassword.length > 0 && newPassword.length > 0) {
      inputError.confirmPasswordError = 'Confirmed password should be the same as the new password.';
    }
    this.setState({
      inputError,
    });
    if (inputError.newPasswordError || inputError.confirmPasswordError) {
      return false;
    }
    return true;
  };

  componentDidMount() {
    const token = window.location.pathname.split('/password/reset/')[1];
    const email = getQueryParams(window.location.search, 'email');
    const componenRoute = sessionStorage.getItem('component_route');
    if (email !== '' && token !== '') {
      this.props.setUpdatePasswordToken({token: token, email: decodeURIComponent(email)});
    } else {
      this.props.setInitUrl(componenRoute);
      this.props.history.push(componenRoute);
    }
  }

  //Onsubmit email sent to server for password reset
  onSignInSubmit = e => {
    const {newPassword, confirmPassword} = this.state.passwordDetails;
    if (this.validate()) {
      this.props.changePasswordFromEmailLink({
        password: newPassword,
        password_confirmation: confirmPassword,
      });
    }
    e.preventDefault();
  };

  //set email in state
  handleChange = e => {
    const {passwordDetails} = {...this.state};
    const {inputError} = {...this.state};

    passwordDetails[e.target.name] = e.target.value;

    //in case if error is their and user entered a character then remove the message
    if (inputError[e.target.name + 'Error'] !== '') {
      inputError[e.target.name + 'Error'] = '';
    }

    this.setState(prevState => ({
      ...prevState,
      passwordDetails: {...passwordDetails},
      inputError: {...inputError},
    }));
  };

  // for remove unwanted space on email typing
  handleChangeOnBlur = e => {
    const {passwordDetails} = {...this.state};
    if (e.target.name === 'email') {
      passwordDetails[e.target.name] = e.target.value.trim();
    }
    this.setState(prevState => ({
      ...prevState,
      passwordDetails: {...passwordDetails},
    }));
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {errorDisplay} = nextProps;
    const currentClientFromUrl = sessionStorage.getItem('currentClientFromUrl');
    nextProps.setThemeColor(getDefaultDomainDetails(currentClientFromUrl).themeColor);
    if (errorDisplay) {
      return {
        passwordDetails: {
          newPassword: '',
          confirmPassword: '',
        },
      };
    }
    return null;
  }

  toggleNewPasswordVisibility = () => {
    this.setState(prevState => ({ showNewPassword: !prevState.showNewPassword }));
  };

  toggleConfirmPasswordVisibility = () => {
    this.setState(prevState => ({ showConfirmPassword: !prevState.showConfirmPassword }));
  };

  render() {
    const {classes, errorMessage, errorDisplay, pageName, updatingPassword, clientConfigration} = this.props;
    const {serverConfig} = !!clientConfigration && clientConfigration;
    const {newPassword, confirmPassword} = this.state.passwordDetails;
    const {newPasswordError, confirmPasswordError} = this.state.inputError;
    return (
      <div className="app-container">
        <div className="app-main-container">
          <div className="login-page forget-password-page">
            <div className="row full-height">
              <div className="col-lg-6 col-md-5 left-section">
                <div className="login-box-wrap forget-password">
                  <div className="logo-wrap">
                    {/* <img className="" alt="icon" src={process.env.PUBLIC_URL + '../../img/amerikooler-logo.svg'} /> */}
                    <img className="" alt="icon" src={serverConfig.institute_logo} />
                  </div>
                  {errorDisplay && pageName === 'changepassword' && (
                    <div className="row form-error-wrap">
                      <span className="default-error">
                        <img src="../../img/error-icon.svg" alt="loading..."></img>
                        {errorMessage}
                      </span>
                    </div>
                  )}
                  
                  {dynamicThemeAllowMenus(getDefaultDomainDetails().instituteName, 'amerikoolerforgetpasdesc') && (
                  <h2>CHANGE PASSWORD</h2>
                  )}
                  {dynamicThemeAllowMenus(getDefaultDomainDetails().instituteName, 'tdnforgetpasdesc') && (
                    <h2>Change password</h2>
                  )}
                  <h3>Please enter your new password and confirm password.</h3>
                  <form className={classes.root} noValidate autoComplete="off" onSubmit={this.onSignInSubmit}>
                    <Grid container>
                      <Grid xs={12} item>
                        <TextField
                          id="newPassword"
                          name="newPassword"
                          label="New Password"
                          variant="outlined"
                          margin="normal"
                          className={`${classes.error} password-field`}
                          fullWidth
                          autoFocus
                          error={newPasswordError ? true : false}
                          disabled={updatingPassword}
                          value={newPassword}
                          onChange={this.handleChange}
                          onBlur={this.handleChangeOnBlur}
                          type={this.state.showNewPassword ? 'text' : 'password'}
                          inputProps={{maxLength: 16}}
                          InputProps={{
                            endAdornment:(
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.toggleNewPasswordVisibility}
                                  edge="end"
                                >
                                  {this.state.showNewPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                        {newPasswordError && (
                          <p
                            className="Error"
                            style={{
                              color: '#E05757',
                              fontSize: '14px',
                              marginTop: '-21px',
                            }}
                          >
                            <ErrorOutlineIcon
                              style={{
                                fontSize: '14px',
                                marginTop: '-4px',
                                marginRight: '10px',
                              }}
                            />
                            {newPasswordError}
                          </p>
                        )}
                      </Grid>
                      <Grid xs={12} item>
                        <TextField
                          id="confirmPassword"
                          name="confirmPassword"
                          label="Confirm Password"
                          variant="outlined"
                          margin="normal"
                          className={`${classes.error} password-field`}
                          fullWidth
                          autoFocus
                          error={confirmPasswordError ? true : false}
                          disabled={updatingPassword}
                          value={confirmPassword}
                          onChange={this.handleChange}
                          onBlur={this.handleChangeOnBlur}
                          type={this.state.showConfirmPassword ? 'text' : 'password'}
                          inputProps={{maxLength: 16}}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.toggleConfirmPasswordVisibility}
                                  edge="end"
                                >
                                  {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                        {confirmPasswordError && (
                          <p
                            className="Error"
                            style={{
                              color: '#E05757',
                              fontSize: '14px',
                              marginTop: '-21px',
                            }}
                          >
                            <ErrorOutlineIcon
                              style={{
                                fontSize: '14px',
                                marginTop: '-4px',
                                marginRight: '10px',
                              }}
                            />
                            {confirmPasswordError}
                          </p>
                        )}
                      </Grid>
                      {dynamicThemeAllowMenus(getDefaultDomainDetails().instituteName, 'amerikoolerforgetpasdesc') && (
                      <Button type="submit" variant="contained" className="default-btn" color="primary" margin="normal" fullWidth disabled={updatingPassword}>
                        UPDATE PASSWORD{updatingPassword && <SubmitLoader />}
                      </Button>
                      )}
                {dynamicThemeAllowMenus(getDefaultDomainDetails().instituteName, 'tdnforgetpasdesc') && (
                  <Button type="submit" variant="contained" className="default-btn" color="primary" margin="normal" fullWidth disabled={updatingPassword}>
                  Update password{updatingPassword && <SubmitLoader />}
                </Button>
                )}
                    </Grid>
                  </form>
                </div>
              </div>
              <div className="col-lg-6 col-md-7 right-section">
                <div className="login-heading-wrap">
                {dynamicThemeAllowMenus(getDefaultDomainDetails().instituteName, 'amerikoolerforgetpasdesc') && (
                  <>
                  <h1>
                    Amerikooler <br />
                    CERTIFIED INSTALLATIONS
                  </h1>
                  <p>
                    Join our growing team of certified Amerikooler installation and service partners and become part of a growing network of skilled providers
                    with access to new business opportunities.
                  </p>
                  </>
                )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      forgotPasswordStart,
      setInitUrl,
      setUpdatePasswordToken,
      changePasswordFromEmailLink,
      setThemeColor
    },
    dispatch,
  );
};

const mapStateToProps = ({auth, error}) => {
  const {updatingPassword, clientConfigration} = auth;
  const {errorDisplay, errorMessage, pageName} = error;

  return {
    updatingPassword,
    errorMessage,
    errorDisplay,
    pageName,
    clientConfigration,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ChangePassword));
