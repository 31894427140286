import { Card, Typography } from "@material-ui/core";
import React from 'react';
const RegistrationSelectRole = ({onSelectRole}) => {
  return (
    <div className="select-rol-section">
      <Typography variant="h1"> Welcome to <br /> Teaching Digital Natives</Typography>
      <span>Please select your role</span>
      <div className="card-wrap">
        <div className="col-md-6">
          <Card className="rol-cta" onClick={() => onSelectRole('instructor')}>
            <img src={process.env.PUBLIC_URL + '../../img/instructor-icon.svg'} alt="" />
            Instructor
          </Card>
        </div>
        <div className="col-md-6">
          <Card className="rol-cta" onClick={() => onSelectRole('student')}>
            <img src={process.env.PUBLIC_URL + '../../img/student-icon.svg'} alt="" />
            Student
          </Card>
        </div>
      </div>
    </div>
  );
};

export default RegistrationSelectRole;
