import * as dashboardType from '../../constants/ActionTypes';


export const fetchDashboardData = data => {
  return {
    type: dashboardType.FETCH_DASHBOARD_DATA,
    payload: data,
  };
};

export const saveDashboardData = data => {
  return {
    type: dashboardType.SAVE_DASHBOARD_DATA,
    payload: data,
  };
};

export const dashboardLoader = val => {
  return {
  type: dashboardType.DASHBOARD_LOADER,
  payload: val,
  };
};

export const fetchDashboardCourseSData = data => {
  return {
    type: dashboardType.FETCH_DASHBOARD_COURSES_DATA,
    payload: data,
  };
};
export const saveDashboardCourseSData = data => {
  return {
    type: dashboardType.SAVE_DASHBOARD_COURSES_DATA,
    payload: data,
  };
};

export const setCurrentSectionData = data => {
  return {
    type: dashboardType.SET_CURRENT_SELECTION_Data,
    payload: data,
  };
};

export const fetchSchoologyAddCourseDetails = data => {
  return {
    type: dashboardType.FETCH_SCHOOLOGY_ADD_COURSE_DETAILS,
    payload: data,
  };
};

export const fetchRemainingSeats = (sectionId) => ({
  type: dashboardType.FETCH_REMAINING_SEATS,
  payload: sectionId,
});

export const setRemainingSeats = (remainingSeats) => ({
  type: dashboardType.SET_REMAINING_SEATS,
  payload: remainingSeats,
});

export const fetchPasswordResetRequests = () => ({
  type: dashboardType.FETCH_PASSWORD_RESET_REQUESTS,
});

export const setPasswordResetRequests = (data) => ({
  type: dashboardType.SET_PASSWORD_RESET_REQUESTS,
  payload: data,
});

export const fetchNewPasswordRequest = (data) => ({
  type: dashboardType.FETCH_NEW_PASSWORD_REQUEST,
  payload: data,
});


export const downloadCertificateForStudentAction = data => {
  return {
    type: dashboardType.DOWNLOAD_CERTIFICATE_FOR_STUDENT,
    payload: data,
  };
};
