import development from './development';
import production from './production';
import staging from './staging';
import test from './jest-test';

// updates env constant based on environment.
const env = (window.appConfig || {}).env || process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development';
const config = {
  development,
  production,
  staging,
  test,
};
if (!config[env]) {
  // console.log('Unknown env: ' + env);
}

export default config[env];
