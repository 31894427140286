import axios from 'util/Api';

export async function studentRegistrationForm(details) {
  const response = await axios.post(
    `/register/student`,
    {
      ...details,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  const result = response.data;
  return result;
}

export async function instructorRegistrationFormn(detail) {
  const response = await axios.post(
    `/register/instructor`,
    {
      ...detail,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  const result = response.data;
  return result;
}

export async function courseCode(courseCode) {
  const response = await axios.get(
    `/get-course-code/${courseCode}`,

    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  const result = response.data;
  return result;
}

export async function instituteCourse() {
  const response = await axios.get(
    `/institute-courses`,

    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  const result = response.data;
  return result;
}

export async function getDistrict() {
  const response = await axios.get(
    `/districts`,

    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  const result = response.data;
  return result;
}