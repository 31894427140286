import * as authType from '../../constants/ActionTypes';

export const setInitUrl = url => {
  return {
    type: authType.INIT_URL,
    payload: url,
  };
};

// Used to show loader for partner configuration
export const setConfigLoader = loader => ({
  type: authType.SET_CONFIG_LOADER,
  payload: loader,
});

// Fetch partner configuration details from server action creator
export const fetchPconfig = code => ({
  type: authType.FETCH_PCONFIG,
  payload: code,
});

// Set partner config
export const setPconfig = config => ({
  type: authType.SET_PCONFIG,
  payload: config,
});

export const userSignIn = user => ({
  type: authType.SIGNIN_USER,
  payload: {
    ...user,
  },
});

export const userAutoSignIn = details => {
  return {
    type: authType.AUTO_SIGNIN,
    payload: details,
  };
};

export const userSignInSuccess = user => ({
  type: authType.SIGNIN_USER_SUCCESS,
  payload: user,
});

export const setSupervisorId = user => ({
  type: authType.SET_SUPERVISOR_ID,
  payload: user,
});

export const setEmployeeId = user => ({
  type: authType.SET_EMPLOYEE_ID,
  payload: user,
});

// reset entire app.
export const flushAppDetails = () => ({
  type: authType.FLUSH_APP_DETAILS,
});

export const userSignOut = () => ({
  type: authType.SIGNOUT_USER,
});

export const userSignOutSuccess = () => ({
  type: authType.SIGNOUT_USER_SUCCESS,
});

export const authLoaderShow = () => ({
  type: authType.ON_SHOW_LOADER,
});

export const authLoaderHide = () => ({
  type: authType.ON_HIDE_LOADER,
});

export const showAuthMessage = message => ({
  type: authType.SHOW_MESSAGE,
  payload: message,
});

export const hideMessage = () => ({
  type: authType.HIDE_MESSAGE,
});

export const forgotPasswordStart = loginDetails => ({
  type: authType.FORGOT_PASSWORD_START,
  payload: loginDetails,
});

export const resetPasswordRequest = loginDetails => ({
  type: authType.RESET_PASSWORD_REQUEST,
  payload: loginDetails,
});


export const resetPasswordResponseData = responseDetails => ({
  type: authType.RESET_PASSWORD_RESPONSE,
  payload: responseDetails,
});
export const clearResetPasswordResponseData = () => ({
  type: authType.CLEAR_RESET_PASSWORD_RESPONSE,
});

export const loginFromECommerceSite = loginDetails => ({
  type: authType.LOGIN_FROM_E_COMMERCE_SITE,
  payload: loginDetails,
});

// loader for update password.
export const setPasswordUpdateLoader = flag => ({
  type: authType.UPDATE_PASSWORD_LOADER,
  payload: flag,
});

// change passwored to new password.
export const updatePassword = details => ({
  type: authType.UPDATE_PASSWORD,
  payload: {...details},
});

// change passwored if forget password.
export const changePasswordFromEmailLink = details => ({
  type: authType.CHANGE_PASSWORD_FROM_EMAIL_LINK,
  payload: {...details},
});

// Change Password - new password updated.
export const changePasswordCompleted = flag => ({
  type: authType.UPDATE_PASSWORD_COMPLETED,
  payload: flag,
});

// email link token set
export const setUpdatePasswordToken = details => ({
  type: authType.SET_UPDATE_PASSWORD_TOKEN,
  payload: {...details},
});

// Fetch Dropdown State list
export const fetchDropdownStateList = country => {
  return {
    type: authType.FETCH_DROPDOWN_STATE_LIST,
    payload: country,
  };
};

export const dropdownStateListSuccess = certifiedInstallerState => {
  return {
    type: authType.DROPDOWN_STATE_LIST_SUCCESS,
    payload: certifiedInstallerState,
  };
};

// Fetch Dropdown city list
export const fetchDropdownCityList = state => {
  return {
    type: authType.FETCH_DROPDOWN_CITY_LIST,
    payload: state,
  };
};

export const dropdownCityListSuccess = dropDownCityList => {
  return {
    type: authType.DROPDOWN_CITY_LIST_SUCCESS,
    payload: dropDownCityList,
  };
};

// Fetch city state by zipcode
export const fetchCityStateByZip = zip => {
  return {
    type: authType.FETCH_CITY_STATE_BY_ZIP,
    payload: zip,
  };
};

export const cityStateDataByZipSuccess = cityStateName => {
  return {
    type: authType.CITY_STATE_DATA_BY_ZIP_SUCCESS,
    payload: cityStateName,
  };
};

export const resendEmailVerificationLink = data => {
  return {
    type: authType.RESEND_EMAIL_VERIFICATION_LINK,
    payload: data,
  };
};

export const signupUserEmailVerified = emailverifiedstatus => {
  return {
    type: authType.VERIFY_USER_EMAIL_EXIST,
    payload: emailverifiedstatus,
  };
};

export const resendEmailVerificationLoaderFunc = data => {
  return {
    type: authType.RESEND_EMAIL_VERIFICATION_LINK_LOADER,
    payload: data,
  };
};

export const changePasswordUpdateFunc = data => {
  return {
    type: authType.CHANGE_PASSWARD_UPDATE,
    payload: data,
  };
};

export const fetchTdnSsoLoginDetails = data => {
  return {
    type: authType.FETCH_TDN_SSO_LOGIN_DETAILS,
    payload: data,
  };
};

export const fetchTdnSchoologylaunchDetails = data => {
  return {
    type: authType.FETCH_TDN_SCHOOLOGY_LAUNCH_DETAILS,
    payload: data,
  };
};

export const saveSsoLoginDetails = data => {
  return {
    type: authType.SAVE_SSO_LOGIN_DETAILS,
    payload: data,
  };
};

export const saveSchoologySsoLoginDetails = data => {
  return {
    type: authType.SAVE_SCHOOLOGY_SSO_LOGIN_DETAILS,
    payload: data,
  };
};

export const schoologyAppCoursesList = data => {
  return {
    type: authType.SCHOOLOGY_APP_COURSES_LIST,
    payload: data,
  };
};
export const clearSchoologySectionId = () => {
  return {
    type: authType.CLEAR_SCHOOLOGY_SECTION_ID,
  };
};

export const isSchoologyAppFalg = data => {
  return {
    type: authType.IS_SCHOOLOGY_APP_FLAG,
    payload: data,
  };
};

export const getRefreshToken = () => {
  return {
    type: authType.GET_REFRESH_TOKEN,
  };
};
